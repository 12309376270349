import { pick, pickBy, map, mergeDeepRight, clone } from 'ramda';
import { extend } from './utils';

export const CLASSNAMES = {
    'ddk-container': 'ddk-container',
    'ddk-page': 'ddk-page'
}

export const FONT_SIZES = {
    'SMALLER': {
        '>font-breakpoint': '15px',
        '<font-breakpoint': '13px',
        'header': '18px'
    },
    'REGULAR': {
        '>font-breakpoint': '17px',
        '<font-breakpoint': '15px',
        'header': '24px'
    },
    'LARGER': {
        '>font-breakpoint': '19px',
        '<font-breakpoint': '17px',
        'header': '30px'
    }
}

export const COLORWAYS = {
    categorical: ['#a6cee3','#1f78b4','#b2df8a','#33a02c','#fb9a99'],

    turbid: ["rgb(232, 245, 171)", "rgb(220, 219, 137)", "rgb(209, 193, 107)", "rgb(199, 168, 83)",
             "rgb(186, 143, 66)", "rgb(170, 121, 60)", "rgb(151, 103, 58)", "rgb(129, 87, 56)",
             "rgb(104, 72, 53)", "rgb(80, 59, 46)", "rgb(57, 45, 37)", "rgb(34, 30, 27)"],

    thermal: ["rgb(3, 35, 51)", "rgb(13, 48, 100)", "rgb(53, 50, 155)", "rgb(93, 62, 153)",
              "rgb(126, 77, 143)", "rgb(158, 89, 135)", "rgb(193, 100, 121)", "rgb(225, 113, 97)",
              "rgb(246, 139, 69)", "rgb(251, 173, 60)", "rgb(246, 211, 70)", "rgb(231, 250, 90)"],

    haline: ["rgb(41, 24, 107)", "rgb(42, 35, 160)", "rgb(15, 71, 153)", "rgb(18, 95, 142)",
             "rgb(38, 116, 137)", "rgb(53, 136, 136)", "rgb(65, 157, 133)", "rgb(81, 178, 124)",
             "rgb(111, 198, 107)", "rgb(160, 214, 91)", "rgb(212, 225, 112)", "rgb(253, 238, 153)"],

    solar: ["rgb(51, 19, 23)", "rgb(79, 28, 33)", "rgb(108, 36, 36)", "rgb(135, 47, 32)",
            "rgb(157, 66, 25)", "rgb(174, 88, 20)", "rgb(188, 111, 19)", "rgb(199, 137, 22)",
            "rgb(209, 164, 32)", "rgb(217, 192, 44)", "rgb(222, 222, 59)", "rgb(224, 253, 74)"],

    ice: ["rgb(3, 5, 18)", "rgb(25, 25, 51)", "rgb(44, 42, 87)", "rgb(58, 60, 125)", "rgb(62, 83, 160)",
          "rgb(62, 109, 178)", "rgb(72, 134, 187)", "rgb(89, 159, 196)", "rgb(114, 184, 205)",
          "rgb(149, 207, 216)", "rgb(192, 229, 232)", "rgb(234, 252, 253)"],

    gray: ["rgb(0, 0, 0)", "rgb(16, 16, 16)", "rgb(38, 38, 38)", "rgb(59, 59, 59)", "rgb(81, 80, 80)",
           "rgb(102, 101, 101)", "rgb(124, 123, 122)", "rgb(146, 146, 145)", "rgb(171, 171, 170)",
           "rgb(197, 197, 195)", "rgb(224, 224, 223)", "rgb(254, 254, 253)"],

    oxy: ["rgb(63, 5, 5)", "rgb(101, 6, 13)", "rgb(138, 17, 9)", "rgb(96, 95, 95)", "rgb(119, 118, 118)",
          "rgb(142, 141, 141)", "rgb(166, 166, 165)", "rgb(193, 192, 191)", "rgb(222, 222, 220)",
          "rgb(239, 248, 90)", "rgb(230, 210, 41)", "rgb(220, 174, 25)"],

    deep: ["rgb(253, 253, 204)", "rgb(206, 236, 179)", "rgb(156, 219, 165)", "rgb(111, 201, 163)",
           "rgb(86, 177, 163)", "rgb(76, 153, 160)", "rgb(68, 130, 155)", "rgb(62, 108, 150)",
           "rgb(62, 82, 143)", "rgb(64, 60, 115)", "rgb(54, 43, 77)", "rgb(39, 26, 44)"],

    dense: ["rgb(230, 240, 240)", "rgb(191, 221, 229)", "rgb(156, 201, 226)", "rgb(129, 180, 227)",
            "rgb(115, 154, 228)", "rgb(117, 127, 221)", "rgb(120, 100, 202)", "rgb(119, 74, 175)",
            "rgb(113, 50, 141)", "rgb(100, 31, 104)", "rgb(80, 20, 66)", "rgb(54, 14, 36)"],

    algae: ["rgb(214, 249, 207)", "rgb(186, 228, 174)", "rgb(156, 209, 143)", "rgb(124, 191, 115)",
            "rgb(85, 174, 91)", "rgb(37, 157, 81)", "rgb(7, 138, 78)", "rgb(13, 117, 71)", "rgb(23, 95, 61)",
            "rgb(25, 75, 49)", "rgb(23, 55, 35)", "rgb(17, 36, 20)"],

    matter: ["rgb(253, 237, 176)", "rgb(250, 205, 145)", "rgb(246, 173, 119)", "rgb(240, 142, 98)",
             "rgb(231, 109, 84)", "rgb(216, 80, 83)", "rgb(195, 56, 90)", "rgb(168, 40, 96)",
             "rgb(138, 29, 99)", "rgb(107, 24, 93)", "rgb(76, 21, 80)", "rgb(47, 15, 61)"],

    speed: ["rgb(254, 252, 205)", "rgb(239, 225, 156)", "rgb(221, 201, 106)", "rgb(194, 182, 59)",
            "rgb(157, 167, 21)", "rgb(116, 153, 5)", "rgb(75, 138, 20)", "rgb(35, 121, 36)", "rgb(11, 100, 44)",
            "rgb(18, 78, 43)", "rgb(25, 56, 34)", "rgb(23, 35, 18)"],

    amp: ["rgb(241, 236, 236)", "rgb(230, 209, 203)", "rgb(221, 182, 170)", "rgb(213, 156, 137)",
          "rgb(205, 129, 103)", "rgb(196, 102, 73)", "rgb(186, 74, 47)", "rgb(172, 44, 36)", "rgb(149, 19, 39)",
          "rgb(120, 14, 40)", "rgb(89, 13, 31)", "rgb(60, 9, 17)"],

    tempo: ["rgb(254, 245, 244)", "rgb(222, 224, 210)", "rgb(189, 206, 181)", "rgb(153, 189, 156)",
            "rgb(110, 173, 138)", "rgb(65, 157, 129)", "rgb(25, 137, 125)", "rgb(18, 116, 117)", "rgb(25, 94, 106)",
            "rgb(28, 72, 93)", "rgb(25, 51, 80)", "rgb(20, 29, 67)"],

    phase: ["rgb(167, 119, 12)", "rgb(197, 96, 51)", "rgb(217, 67, 96)", "rgb(221, 38, 163)", "rgb(196, 59, 224)",
            "rgb(153, 97, 244)", "rgb(95, 127, 228)", "rgb(40, 144, 183)", "rgb(15, 151, 136)", "rgb(39, 153, 79)",
            "rgb(119, 141, 17)", "rgb(167, 119, 12)"],

    balance: ["rgb(23, 28, 66)", "rgb(41, 58, 143)", "rgb(11, 102, 189)", "rgb(69, 144, 185)", "rgb(142, 181, 194)",
              "rgb(210, 216, 219)", "rgb(230, 210, 204)", "rgb(213, 157, 137)", "rgb(196, 101, 72)", "rgb(172, 43, 36)",
              "rgb(120, 14, 40)", "rgb(60, 9, 17)"],

    plotly: ["#09ffff", "#19d3f3", "#e763fa", "#ab63fa", "#636efa", "#00cc96", "#EF553B"],

    delta: ["rgb(16, 31, 63)", "rgb(38, 62, 144)", "rgb(30, 110, 161)", "rgb(60, 154, 171)", "rgb(140, 193, 186)",
            "rgb(217, 229, 218)", "rgb(239, 226, 156)", "rgb(195, 182, 59)", "rgb(115, 152, 5)", "rgb(34, 120, 36)",
            "rgb(18, 78, 43)", "rgb(23, 35, 18)"],

    curl: ["rgb(20, 29, 67)", "rgb(28, 72, 93)", "rgb(18, 115, 117)", "rgb(63, 156, 129)", "rgb(153, 189, 156)",
           "rgb(223, 225, 211)", "rgb(241, 218, 206)", "rgb(224, 160, 137)", "rgb(203, 101, 99)", "rgb(164, 54, 96)",
    "rgb(111, 23, 91)", "rgb(51, 13, 53)"]
}

export const CUSTOMIZED_COLORPICKER_COLORS = [
    // designed by http://clrs.cc/ & sidi
    '#004172', // SIDI NAVY
    '#1a9dff', // SIDI BLUE
    '#b9cfed', // SIDI INK BLUE
    '#80fcff', // SIDI AQUA
    '#3D9970', // CLRS OLIVE
    '#2ECC40', // CLRS GREEN
    '#75c9be', // SIDI TEAL GREEN
    '#2bfebe', // SIDI NEON GREEN
    '#ffd15f', // SIDI YELLOW
    '#fde725', // VIRIDIS YELLOW
    '#b79a5e', // SIDI BROWN
    '#ff7f1f', // SIDI ORANGc
    '#e80a0a', // SIDI RED
    '#ff2c6d', // SIDI PINK
    '#A239CA', // CLRS FUCHSIA
    '#813772', // CLRS POSY
    '#dddddd', // SIDI GREY
    '#aaaaaa', // SIDI SILVER
    '#ffffff', // WHITE
    '#232323', // SIDI BLACK
];

export const BOX_SHADOWS = {
    none: {
        name: 'none',
        boxShadow: 'none'
    },
    light: {
        name: 'light',
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)'
    },
    medium: {
        name: 'medium',
        boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)'
    },
    heavy: {
        name: 'heavy',
        boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)'
    }
}

export const BORDERS = {
    underlined: {
        name             : 'underlined',
        borderTopWidth   : 0,
        borderRightWidth : 0,
        borderLeftWidth  : 0,
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',

        borderRadius     : 0,
        inputFocus: {
            outline: 'transparent'
        }
    },

    rounded: {
        name: 'rounded',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '3px'
    },

    square: {
        name: 'square',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: 0
    },

    double: {
        name: 'double',
        borderWidth: '4px',
        borderStyle: 'double',
        borderRadius: 0
    },

    threeD: {
        name: 'threeD',
        borderWidth: '3px',
        borderStyle: 'outset',
        borderRadius: 0
    }
}

function setThemeVar(key, value, context) {
    let that = context;
    console.log("setter invoked");
    Object.defineProperty(
      that, key, 
      {writable: true, enumerable: true, configurable: true}
    );
    that[key] = value;
}

// The rest of the theme colors are embedded in the `ThemeEditor`
/* THEMES.light is used as a "reference" theme in App to ensure no
   undefined variables */

/* Reminder: any new variables should be populated
   across this app - see
   https://github.com/plotly/dash-design-kit/pull/273 */

export const THEMES = {
    init : function() {
        window.dashTheme = this.light;
        delete this.init;
        return this;
    },
    light:                  {
        accent:             '#1f78b4',
        accent_positive:    '#017500',
        accent_negative:    '#C20000',
        background_content: '#F9F9F9',
        background_page:    '#F2F2F2',
        border:             '#e2e2e2',
        border_style:       BORDERS.underlined,

        card_margin:        '15px',
        card_padding:       '5px',
        card_border: function() { return {
            width:          '0px 0px 0px 0px',
            style:          'solid',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_border);
            },
            radius:         '0px',
        }}(),
        get card_background_color() { return this.background_content; },
        set card_background_color(value) {
            setThemeVar('card_background_color', value, this);
        },
        card_box_shadow:     '0px 1px 3px rgba(0,0,0,0.12), 0px 1px 2px rgba(0,0,0,0.24)',
        /* partial workaround for overlapping borders with 0 margins */
        card_outline: function() { return {
            width: '0px',
            style: 'solid',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_outline);
            },
        }}(),

        card_header_margin: '0px',
        card_header_padding: '10px',
        card_header_border: function() { return {
            width:           '0px 0px 1px 0px',
            style:           'solid',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_header_border);
            },
            radius:          '0px'
        }}(),
        get card_header_background_color() { return this.background_content; },
        set card_header_background_color(value) {
            setThemeVar('card_header_background_color', value, this);
        },
        card_header_box_shadow: '0px 0px 0px rgba(0,0,0,0)',
        breakpoint_font: '1200px',
        // If a user has 2 graphs stacked side-by-side,
        // then the graphs will become at most 350px wide
        breakpoint_stack_blocks: '700px',

        colorway: [
            "#119dff",
            "#66c2a5",
            "#fc8d62",
            "#e78ac3",
            "#a6d854",
            "#ffd92f",
            "#e5c494",
            "#b3b3b3"
        ],
        colorscale: [
            "#1f78b4",
            "#4786bc",
            "#6394c5",
            "#7ba3cd",
            "#92b1d5",
            "#a9c0de",
            "#bed0e6",
            "#d4dfee",
            "#eaeff7",
            "#ffffff"
        ],
        font_family:              'Open Sans',
        font_family_header:       'Open Sans',
        font_family_headings:     'Open Sans',
        font_size:                FONT_SIZES['REGULAR']['>font-breakpoint'],
        font_size_smaller_screen: FONT_SIZES['REGULAR']['<font-breakpoint'],
        font_size_header:         FONT_SIZES['REGULAR']['header'],
        header_margin:           '0px 0px 15px 0px',
        header_padding:          '0px',
        header_border: function() { return {
            width:           '0px 0px 0px 0px',
            style:           'solid',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_header_border);
            },
            radius:          '0px'
        }}(),
        get header_background_color() { return this.background_content; },
        set header_background_color(value) {
            setThemeVar('header_background_color', value, this);
        },
        get header_box_shadow() { return this.card_box_shadow; },
        set header_box_shadow(value) {
            setThemeVar('header_box_shadow', value, this);
        },
        text: '#606060',
        report_background_content: '#FAFBFC',
        report_background_page: 'white',
        report_text: 'black',
        report_font_family: 'Computer Modern',
        report_font_size: '12px'
    },
    // fonts and colors subset for Preset tab
    light_preset_subset:                  {
        accent:             '#1f78b4',
        accent_positive:    '#017500',
        accent_negative:    '#C20000',
        background_content: '#F9F9F9',
        background_page:    '#F2F2F2',
        border:             '#e2e2e2',
        card_border: function() { return {
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_border);
            },
        }}(),
        get card_background_color() { return this.background_content; },
        set card_background_color(value) {
            setThemeVar('card_background_color', value, this);
        },
        card_outline: function() { return {
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_outline);
            },
        }}(),
        card_header_border: function() { return {
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_header_border);
            },
        }}(),
        get card_header_background_color() { return this.background_content; },
        set card_header_background_color(value) {
            setThemeVar('card_header_background_color', value, this);
        },
        colorway: [
            "#119dff",
            "#66c2a5",
            "#fc8d62",
            "#e78ac3",
            "#a6d854",
            "#ffd92f",
            "#e5c494",
            "#b3b3b3"
        ],
        colorscale: [
            "#1f78b4",
            "#4786bc",
            "#6394c5",
            "#7ba3cd",
            "#92b1d5",
            "#a9c0de",
            "#bed0e6",
            "#d4dfee",
            "#eaeff7",
            "#ffffff"
        ],
        font_family:              'Open Sans',
        font_family_header:       'Open Sans',
        font_family_headings:     'Open Sans',
        font_size:                FONT_SIZES['REGULAR']['>font-breakpoint'],
        font_size_smaller_screen: FONT_SIZES['REGULAR']['<font-breakpoint'],
        font_size_header:         FONT_SIZES['REGULAR']['header'],
        header_border: function() { return {
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_header_border);
            },
        }}(),
        get header_background_color() { return this.background_content; },
        set header_background_color(value) {
            setThemeVar('header_background_color', value, this);
        },
        get header_box_shadow() { return this.card_box_shadow; },
        set header_box_shadow(value) {
            setThemeVar('header_box_shadow', value, this);
        },
        text: '#606060',
        report_background_content: '#FAFBFC',
        report_background_page: 'white',
        report_text: 'black',
        report_font_family: 'Computer Modern',
        report_font_size: '12px'
    },
}.init();

const isContainerKey = (val, key) => key.startsWith('card_', 'header_', 'menu_');
const THEME_CONTAINERS_SUBSET = pickBy(isContainerKey, THEMES.light);

// TODO: consider whether map over merge is the best approach here
export const CONTAINER_THEMES = {
    box_shadow : {
        card_border: function() { return {
            width: '0px',
            radius: '0px',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_border);
            }
        }}(),
        get card_background_color() { return this.background_content; },
        set card_background_color(value) {
            setThemeVar('card_background_color', value, this);
        },
        card_box_shadow: '0px 1px 3px rgba(0,0,0,0.12), 0px 1px 2px rgba(0,0,0,0.24)',
        card_header_box_shadow: '0px 0px 0px rgba(0,0,0,0)',
        card_outline: function() { return {
            width: '0px',
            style: 'solid',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_outline);
            },
        }}(),
        card_margin: '15px',
        card_header_border: function() { return {
            width:           '0px',
            style:           'solid',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_header_border);
            },
            radius:          '0px'
        }}(),
        header_border: function() { return {
            width:           '0px',
            style:           'solid',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.header_border);
            },
            radius:          '0px'
        }}(),
        header_margin:           '0px 0px 15px 0px',
        get header_box_shadow() { return this.card_box_shadow; },
        set header_box_shadow(value) {
            setThemeVar('header_box_shadow', value, this);
        },
        get header_background_color() { return this.background_content; },
        set header_background_color(value) {
            setThemeVar('header_background_color', value, this);
        },
    },
    minimal_and_flat: {
        card_border: function() { return {
            'width': '0px',
            'radius': '0px',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_border);
            }
        }}(),
        get card_background_color() { return this.background_content; },
        set card_background_color(value) {
            setThemeVar('card_background_color', value, this);
        },
        card_box_shadow: '0px 0px 0px rgba(0,0,0,0)',
        card_header_box_shadow: '0px 0px 0px rgba(0,0,0,0)',
        card_outline: function() { return {
            width: '0px',
            style: 'solid',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_outline);
            }
        }}(),
        card_header_border: function() { return {
            width:           '0px',
            style:           'solid',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_header_border);
            },
            radius:          '0px'
        }}(),
        card_margin: '15px',
        get header_background_color() { return this.background_page; },
        set header_background_color(value) {
            setThemeVar('header_background_color', value, this);
        },
        header_border: function() { return {
            width:           '0px',
            style:           'solid',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.header_border);
            },
            radius:          '0px'
        }}(),
        header_margin:           '0px 0px 15px 0px',
        header_box_shadow: 'none',
    },
    tight_and_bordered: {
        card_margin: 0,
        get card_background_color() { return this.background_content; },
        set card_background_color(value) {
            setThemeVar('card_background_color', value, this);
        },
        card_border: function() { return {
            'width': '1px',
            'radius': '0px',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_border);
            }
        }}(),
        card_outline: function() { return {
            width: '1px',
            style: 'solid',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_outline);
            }
        }}(),
        card_header_border: function() { return {
            width:           '0px 0px 1px 0px',
            style:           'solid',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_header_border);
            },
            radius:          '0px'
        }}(),
        card_box_shadow: '0px 0px 0px rgba(0,0,0,0)',
        card_header_box_shadow: '0px 0px 0px rgba(0,0,0,0)',
        header_border: function() { return {
            width:           '0px',
            style:           'solid',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_header_border);
            },
            radius:          '0px'
        }}(),
        get header_background_color() { return this.background_page; },
        set header_background_color(value) {
            setThemeVar('header_background_color', value, this);
        },
        header_margin: '0px',
        header_box_shadow: 'none'
    },
    future_console: {
        card_header_border: function() { return {
            style: 'dashed',
            width: '0px 0px 2px 0px',
            get color() { return window.dashTheme.card_border.color; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_header_border);
            },
            radius:          '0px'
        }}(),
        get card_background_color() { return this.background_content; },
        set card_background_color(value) {
            setThemeVar('card_background_color', value, this);
        },
        card_outline: function() { return {
            width: '0px',
            style: 'solid',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_outline);
            },
        }}(),
        card_box_shadow: '0px 0px 0px rgba(0,0,0,0)',
        card_header_box_shadow: '0px 0px 0px rgba(0,0,0,0)',
        card_border: function() { return {
            width: '1px',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_border);
            }
        }}(),
        card_margin: '15px',
        header_border: function() { return {
            width:           '0px',
            style:           'solid',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_header_border);
            },
            radius:          '0px'
        }}(),
        get header_background_color() { return this.background_content; },
        set header_background_color(value) {
            setThemeVar('header_background_color', value, this);
        },
        header_margin: '0px 0px 15px 0px',
        header_box_shadow: 'none'
    }
}

export const PRESET_THEMES = [
    // Light 1
    {
        background_content: '#F9F9F9',
        background_page: '#F2F2F2',
        accent: '#fa4f56',
        border: '#e2e2e2',
        text: '#606060',
        accent_positive: '#33ffe6',
        accent_negative: '#ff2c6d',
        font_family: 'Raleway',
        font_family_header: 'Roboto',
        font_family_headings: 'Roboto',
        // manually selected from suggested colorway in Editor
        colorway: [
            "#fa4f56",
            "#4c78a8",
            "#f58518",
            "#72b7b2",
            "#54a24b",
            "#eeca3b",
            "#b279a2",
            "#ff9da6",
            "#9d755d",
            "#bab0ac"
        ],
        // manually selected from suggested colorscale in Editor
        colorscale: [
            "#fa4f56",
            "#fe6767",
            "#ff7c79",
            "#ff908b",
            "#ffa39d",
            "#ffb6b0",
            "#ffc8c3",
            "#ffdbd7",
            "#ffedeb",
            "#ffffff"
        ],
    },
    // Light 2
    {
        background_content: '#edf3f4',
        background_page: '#d6e4ea',
        accent: '#004172',
        border: '#8EA9C1',
        text: '#718BA5',
        accent_positive: '#33ffe6',
        accent_negative: '#ff2c6d',
        font_family: 'Quicksand',
        font_family_header: 'PT Sans',
        font_family_headings: 'PT Sans',
        colorway: [
            "#004172",
            "#3366cc",
            "#dc3912",
            "#ff9900",
            "#109618",
            "#990099",
            "#0099c6",
            "#dd4477",
            "#66aa00",
            "#b82e2e"
        ],
        colorscale: [
            "#004172",
            "#2f5381",
            "#4b6790",
            "#657b9f",
            "#7f8faf",
            "#98a5bf",
            "#b1bbce",
            "#cbd1de",
            "#e5e8ef",
            "#ffffff"
        ],
    },

    // Light 3
    {
        background_content: '#F7F9F9',
        background_page: '#eaeaea',
        accent: '#FF8827',
        border: '#D1D1D1',
        text: '#6D6D6D',
        accent_positive: '#33ffe6',
        accent_negative: '#ff2c6d',
        font_family: 'Open Sans',
        font_family_header: 'Dosis',
        font_family_headings: 'Dosis',
        colorway: [
            "#ff8827",
            "#66c2a5",
            "#8da0cb",
            "#e78ac3",
            "#a6d854",
            "#ffd92f",
            "#e5c494",
            "#b3b3b3"
        ],
        colorscale: [
            "#ff8827",
            "#ff9542",
            "#ffa35b",
            "#ffb072",
            "#ffbd89",
            "#ffcaa0",
            "#ffd7b8",
            "#ffe4cf",
            "#fff2e7",
            "#ffffff"
        ],
    },

    // Dark 1
    {
        background_content: '#2d3038',
        background_page: '#23262E',
        accent: '#FFD15F',
        border: '#53555B',
        text: '#95969A',
        accent_positive: '#33ffe6',
        accent_negative: '#ff2c6d',
        font_family: 'Ubuntu Light',
        font_family_header: 'Quattrocento Sans',
        font_family_headings: 'Quattrocento Sans',
        colorway: [
            "#ffd15f",
            "#4c78a8",
            "#f58518",
            "#e45756",
            "#72b7b2",
            "#54a24b",
            "#b279a2",
            "#ff9da6",
            "#9d755d",
            "#bab0ac"
        ],
        colorscale: [
            "#ffd15f",
            "#eabe54",
            "#d4ab48",
            "#c0983e",
            "#ab8633",
            "#977428",
            "#84631e",
            "#715214",
            "#5e420a",
            "#4c3200"
        ],
    },

    // Dark 2
    {
        background_content: '#1B2444',
        background_page: '#161D33',
        accent: '#80FCFF',
        border: '#525F89',
        text: '#768DB7',
        accent_positive: '#33ffe6',
        accent_negative: '#ff2c6d',
        font_family: 'Arimo',
        font_family_header: 'Lora',
        font_family_headings: 'Lora',
        colorway: [
            "#80fcff",
            "#fbb4ae",
            "#b3cde3",
            "#ccebc5",
            "#decbe4",
            "#fed9a6",
            "#ffffcc",
            "#e5d8bd",
            "#fddaec"
        ],
        colorscale: [
            "#80fcff",
            "#72e6e9",
            "#63d1d4",
            "#55bcbf",
            "#48a7ab",
            "#3a9397",
            "#2d7f84",
            "#1f6c71",
            "#10595e",
            "#00474c"
        ],

    },

    // Dark 3
    {
        background_content: '#1D262F',
        background_page: '#242e3f',
        accent: '#2BFEBE',
        border: '#5C8CBE',
        text: '#87B4E5',
        accent_positive: '#33ffe6',
        accent_negative: '#ff2c6d',
        font_family: 'Noto Sans',
        font_family_header: 'Playfair',
        font_family_headings: 'Playfair',
        colorway: [
            "#2bfebe",
            "#4c78a8",
            "#f58518",
            "#e45756",
            "#54a24b",
            "#eeca3b",
            "#b279a2",
            "#ff9da6",
            "#9d755d",
            "#bab0ac"
        ],
        colorscale: [
            "#2bfebe",
            "#27e8aa",
            "#22d396",
            "#1ebd83",
            "#19a971",
            "#14945f",
            "#0f814d",
            "#096e3c",
            "#045b2b",
            "#00491b"
        ],
    },

    // Neutral 1
    {
        background_content: '#E8DAC5',
        background_page: '#F2E9DD',
        accent: '#232323',
        border: '#d3bd98',
        text: '#493D32',
        accent_positive: '#33ffe6',
        accent_negative: '#ff2c6d',
        font_family: 'Abel',
        font_family_header: 'PT Serif',
        font_family_headings: 'PT Serif',
        colorway: [
            "#232323",
            "#1b9e77",
            "#d95f02",
            "#7570b3",
            "#e7298a",
            "#66a61e",
            "#e6ab02",
            "#a6761d"
        ],
        colorscale: [
            "#232323",
            "#363636",
            "#4b4b4b",
            "#606060",
            "#777777",
            "#8e8e8e",
            "#a6a6a6",
            "#bebebe",
            "#d7d7d7",
            "#f1f1f1"
        ],
    },

    // Neutral 2
    {
        background_content: '#4c4039',
        background_page: '#564b43',
        accent: '#fecb52',
        border: '#8c745a',
        text: '#ddc8b1',
        accent_positive: '#33ffe6',
        accent_negative: '#ff2c6d',
        font_family: 'Libre Franklin',
        font_family_header: 'Oswald',
        font_family_headings: 'Oswald',
        colorway: [
            "#fecb52",
            "#4c78a8",
            "#f58518",
            "#e45756",
            "#72b7b2",
            "#54a24b",
            "#b279a2",
            "#ff9da6",
            "#9d755d",
            "#bab0ac"
        ],
        colorscale: [
            "#fecb52",
            "#e9b848",
            "#d4a63f",
            "#c09335",
            "#ac822c",
            "#987023",
            "#855f1a",
            "#724f11",
            "#603f09",
            "#4e3000"
        ],
    },

    // Neutral 3
    {
        background_content: '#828187',
        background_page: '#717075',
        accent: '#dddddd',
        border: '#cccccc',
        text: '#f1f1f1',
        accent_positive: '#33ffe6',
        accent_negative: '#ff2c6d',
        font_family: 'Poppins',
        font_family_header: 'Source Sans',
        font_family_headings: 'Source Sans',
        colorway: [
            "#dddddd",
            "#b3e2cd",
            "#fdcdac",
            "#cbd5e8",
            "#f4cae4",
            "#e6f5c9",
            "#fff2ae",
            "#f1e2cc"
         ],
        colorscale: [
            "#dddddd",
            "#c9c9c9",
            "#b5b5b5",
            "#a2a2a2",
            "#8f8f8f",
            "#7c7c7c",
            "#6a6a6a",
            "#595959",
            "#484848",
            "#383838"
        ],
    },

    // Different 1
    {
        background_content: '#3d208e',
        background_page: '#2C0772',
        accent: '#C7FFFB',
        border: '#8D7DFF',
        text: '#CDCCFF',
        accent_positive: '#33ffe6',
        accent_negative: '#ff2c6d',
        font_family: 'Mukta Light',
        font_family_header: 'Arimo',
        font_family_headings: 'Arimo',
        colorway: [
            "#c7fffb",
            "#66c2a5",
            "#fc8d62",
            "#8da0cb",
            "#e78ac3",
            "#a6d854",
            "#ffd92f",
            "#e5c494"
        ],
        colorscale: [
            "#c7fffb",
            "#b1e9e5",
            "#9cd2cf",
            "#87bdb9",
            "#72a8a4",
            "#5e9390",
            "#4a7f7c",
            "#366b68",
            "#225855",
            "#0b4543"
        ],
    },

    // Different 2
    {
        background_content: '#1F5869',
        background_page: '#153F4C',
        accent: '#75C9BE',
        border: '#63A8A6',
        text: '#CBE2E2',
        accent_positive: '#33ffe6',
        accent_negative: '#ff2c6d',
        font_family: 'Nunito',
        font_family_header: 'Noto Serif',
        font_family_headings: 'Noto Serif',
        colorway: [
            "#75c9be",
            "#ffffb3",
            "#bebada",
            "#fb8072",
            "#80b1d3",
            "#fdb462",
            "#b3de69",
            "#fccde5",
            "#d9d9d9",
            "#bc80bd",
            "#ccebc5",
            "#ffed6f"
         ],
         colorscale: [
            "#75c9be",
            "#86cfc5",
            "#96d5cc",
            "#a6dbd3",
            "#b5e1da",
            "#c4e7e2",
            "#d3ede9",
            "#e2f3f0",
            "#f0f9f8",
            "#ffffff"
        ],
    },

    // Different 3
    {
        background_content: '#303aa5',
        background_page: '#1a1a82',
        accent: '#ffc97b',
        border: '#7892ff',
        text: '#d7e9ff',
        accent_positive: '#33ffe6',
        accent_negative: '#ff2c6d',
        font_family: 'Montserrat',
        font_family_header: 'Open Sans',
        font_family_headings: 'Open Sans',
        colorway: [
            "#ffc97b",
            "#8dd3c7",
            "#ffffb3",
            "#bebada",
            "#fb8072",
            "#80b1d3",
            "#b3de69",
            "#fccde5",
            "#d9d9d9",
            "#bc80bd",
            "#ccebc5",
            "#ffed6f"
        ],
        colorscale: [
            "#ffc97b",
            "#eab66c",
            "#d5a45e",
            "#c19150",
            "#ad8042",
            "#9a6e35",
            "#865d28",
            "#744d1b",
            "#613d0e",
            "#4f2e00"
        ],
    },
].map(theme_preset => {
    /*
     * extend mutates its returned object
     * hence the duplication/cloning here
     */
    var base = extend({}, THEMES.light_preset_subset);
    var preset = clone(theme_preset);
    var extended_preset = extend(base, preset)
    return extended_preset;
});
