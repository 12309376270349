import { css, objectToStyles } from '../utils.js';
import * as R          from 'ramda';
import chroma          from 'chroma-js';

const themedStyles = css`
    .ddk-container.ddk-container .CalendarDay--hovered,
    .ddk-container.ddk-container .CalendarDay--hovered-span {
      background-color : ${props => props.theme.background_content}
      color            : ${props => props.theme.accent}
      border-color     : ${props => props.theme.accent}
    }
    .ddk-container.ddk-container .CalendarDay--selected-start,
    .ddk-container.ddk-container .CalendarDay--selected-end,
    .ddk-container.ddk-container .CalendarDay--selected {
      background-color: ${props => props.theme.background_content}
      border-color: ${props => props.theme.accent}
      color: ${props => props.theme.accent}
      font-weight: bold;
    }
    .ddk-container.ddk-container .DateInput__display-text--focused {
      color: ${props => props.theme.text} }
    .ddk-container.ddk-container .DateInput__display-text--has-input {
      color: ${props => props.theme.text}
    }
    .ddk-container.ddk-container .DateInput__display-text--disabled {
        color: ${props => chroma(props.theme.text).alpha(0.5).css()};
    }
    .ddk-container.ddk-container .SingleDatePickerInput {
        ${props => objectToStyles(props.theme.border_style)}
        width: 100%;
    }
    .ddk-container.ddk-container .SingleDatePickerInput
    .DateInput {
        width: 100%;
    }
    .ddk-container.ddk-container .SingleDatePickerInput
    .DateInput_input {
        width: 100%;
    }
    .ddk-container.ddk-container .DateInput {
      line-height: 12px;
      font-size: inherit;
      font-weight: normal;
    }
    .ddk-container.ddk-container .SingleDatePicker {
        width: 100%;
    }
    .ddk-container.ddk-container .SingleDatePicker
    .SingleDatePickerInput {
        width: 100%;
        border-bottom: none;
    }
    .ddk-container.ddk-container .DateRangePicker {
        width: 100%;
    }
    .ddk-container.ddk-container .DateRangePicker
    .DateInput_input {
        border-bottom: none;
    }
    .ddk-container.ddk-container .DateRangePicker li,
    .ddk-container.ddk-container .SingleDatePicker li {
        margin-bottom: initial;
        margin-left: initial;
        padding-left: initial;
    }
    .ddk-container.ddk-container .DateRangePicker__picker {
        z-index: 1002; /* one above the plotly.js modebar ;) */
    }
    .ddk-container.ddk-container .DateInput--with-caret::before {
        display: none;
    }
    .ddk-container.ddk-container .DateInput--with-caret::after {
        display: none;
    }
    .ddk-container.ddk-container .DayPickerNavigation--horizontal .DayPickerNavigation__prev,
    .ddk-container.ddk-container .DayPickerNavigation--horizontal .DayPickerNavigation__next {
        border-radius      : ${props => R.path(['theme', 'border_style', 'borderRadius'], props)};
        border-top-width   : ${props => R.path(['theme', 'border_style', 'borderTopWidth'], props)};
        border-right-width : ${props => R.path(['theme', 'border_style', 'borderRightWidth'], props)};
        border-left-width  : ${props => R.path(['theme', 'border_style', 'borderLeftWidth'], props)};
    }
    .ddk-container.ddk-container .DayPickerNavigation--horizontal .DayPickerNavigation__prev:hover,
    .ddk-container.ddk-container .DayPickerNavigation--horizontal .DayPickerNavigation__next:hover {
        border-color: ${props => props.theme.accent}
        cursor: pointer;
    }
    .ddk-container.ddk-container .DayPickerKeyboardShortcuts__show {
        display: none;
    }
    .ddk-container.ddk-container .DayPicker--horizontal {
        border-radius : ${props => R.path(['theme', 'border_style', 'borderRadius'], props)};
    }
    .ddk-container.ddk-container .DateInput--disabled {
      	background-color: ${props => chroma(props.theme.background_content).alpha(0.5).css()};
    }
    .ddk-container.ddk-container .DateInput__display-text--disabled {
        font-style: normal;
    }
    .ddk-container.ddk-container .DateInput__display-text--focused {
        color: ${props => props.theme.accent};
        font-weight: bold;
    }
    /* SingleDatePicker */
    .ddk-container.ddk-container .SingleDatePickerInput:hover {
        border-color: ${props => props.theme.accent}
    }
    .ddk-container.ddk-container .SingleDatePickerInput.SingleDatePickerInput--disabled {
        border: none;
    }
    .ddk-container.ddk-container .SingleDatePicker__picker {
        top: 36px;
    }
    .ddk-container.ddk-container .SingleDatePickerInput__clear-date:focus,
    .ddk-container.ddk-container .SingleDatePickerInput__clear-date--hover {
        background-color: ${props => props.theme.background_content}
    }
    /* Portal */
    .ddk-container.ddk-container .SingleDatePicker__picker--full-screen-portal {
        background-color: ${props => chroma(props.theme.background_content).alpha(0.8).css()};
    }
    .ddk-container.ddk-container .DateRangePicker__picker--full-screen-portal {
        background-color: ${props => chroma(props.theme.background_content).alpha(0.8).css()};
    }
    /* DateRangePicker */
    .ddk-container.ddk-container .DateRangePicker td:first-child.CalendarDay--selected-span:not(.CalendarDay--selected-start) {
        border-left: none;
    }
    .ddk-container.ddk-container .DateRangePicker td:last-child.CalendarDay--selected-span:not(.CalendarDay--selected-end) {
        border-right: none;
    }
    .ddk-container.ddk-container .DateRangePickerInput--disabled {
      	background-color: ${props => chroma(props.theme.background_content).alpha(0.5).css()};
        border: none;
    }
    .ddk-container.ddk-container .CalendarDay--selected-span {
        background-color    : ${props => props.theme.background_content}
        color               : ${props => props.theme.accent});
        border-right        : none;
        border-left         : none;
        border-top-color    : 1px double ${props => props.theme.accent};
        border-bottom-color : ${props => props.theme.accent};
    }
    .ddk-container.ddk-container .CalendarDay--selected-start {
        border-left: 1px double ${props => props.theme.accent};
        border-right: none;
    }
    .ddk-container.ddk-container .CalendarDay--selected-end {
        border-right-color: 1px double ${props => props.theme.accent};
        border-left: none;
    }
    .ddk-container.ddk-container .CalendarDay--valid.CalendarDay--selected-span.CalendarDay--hovered {
        border: 1px double ${props => props.theme.accent};
        font-weight: bold;
    }
    .ddk-container.ddk-container .DateRangePickerInput__clear-dates--hover:enabled:hover {
        background-color: inherit;
    }
    .ddk-container.ddk-container .SingleDatePickerInput__clear-date svg {
        fill: ${props => props.theme.border};
    }
    .ddk-container.ddk-container .DateRangePickerInput__close-icon svg {
        fill: ${props => props.theme.border};
    }
    .ddk-container.ddk-container button.DateRangePickerInput__clear-dates  {
        box-shadow: none;
    }
    .ddk-container.ddk-container button.SingleDatePickerInput__clear-date {
        box-shadow: none;
    }
    /* Remove box shadoow added to all base buttons */
    .ddk-container.ddk-container .CalendarDay__button {
        box-shadow: none;
    }
`
export default themedStyles;
