import { css } from '../utils.js';

const themed = key => props => props.theme[key];

// "Minor Third" scalar
// from https://type-scale.com
const FONT_SCALE = 1.2;

const themedStyles = css`
    .ddk-container.ddk-container blockquote {
      border-left-color: ${props => props.theme.border};
    }
    .ddk-container.ddk-container a {
      color: ${props => props.theme.accent}; }
    .ddk-container.ddk-container a:hover {
      color: ${props => props.theme.accent}; }
    .ddk-container.ddk-container .button,
    .ddk-container.ddk-container button,
    .ddk-container.ddk-container input[type="submit"],
    .ddk-container.ddk-container input[type="reset"],
    .ddk-container.ddk-container input[type="button"] {
      color            : ${props => props.theme.accent};
      border: 1px solid ${props => props.theme.accent};
      background-color : ${props => props.theme.background_content};
      transition       : all 0.2s ease;
    }

    .ddk-container.ddk-container input::placeholder {
        color: ${props => props.theme.text};
        opacity: 0.6;
    }

    .ddk-container.ddk-container .button:active,
    .ddk-container.ddk-container button:active,
    .ddk-container.ddk-container input[type="submit"]:active,
    .ddk-container.ddk-container input[type="reset"]:active,
    .ddk-container.ddk-container input[type="button"]:active {
        box-shadow: none;
    }
    .ddk-container.ddk-container .button:hover:enabled,
    .ddk-container.ddk-container button:hover:enabled,
    .ddk-container.ddk-container input[type="submit"]:hover:enabled,
    .ddk-container.ddk-container input[type="reset"]:hover:enabled,
    .ddk-container.ddk-container input[type="button"]:hover:enabled,
    .ddk-container.ddk-container .button:focus,
    .ddk-container.ddk-container button:focus,
    .ddk-container.ddk-container input[type="submit"]:focus,
    .ddk-container.ddk-container input[type="reset"]:focus,
    .ddk-container.ddk-container input[type="button"]:focus {
      color : ${props => props.theme.colorway[1]};
      border-color : ${props => props.theme.colorway[1]};
      outline: none;
    }

    .ddk-container.ddk-container .button.button-primary,
    .ddk-container.ddk-container button.button-primary,
    .ddk-container.ddk-container input[type="submit"].button-primary,
    .ddk-container.ddk-container input[type="reset"].button-primary,
    .ddk-container.ddk-container input[type="button"].button-primary {
      color: ${props => props.theme.background_content};
      background-color: ${props => props.theme.accent};
      border-color: ${props => props.theme.accent};
    }

    .ddk-container.ddk-container .button.button-primary:hover:enabled,
    .ddk-container.ddk-container button.button-primary:hover:enabled,
    .ddk-container.ddk-container input[type="submit"].button-primary:hover:enabled,
    .ddk-container.ddk-container input[type="reset"].button-primary:hover:enabled,
    .ddk-container.ddk-container input[type="button"].button-primary:hover:enabled,
    .ddk-container.ddk-container .button.button-primary:focus,
    .ddk-container.ddk-container button.button-primary:focus,
    .ddk-container.ddk-container input[type="submit"].button-primary:focus,
    .ddk-container.ddk-container input[type="reset"].button-primary:focus,
    .ddk-container.ddk-container input[type="button"].button-primary:focus {
      color: ${props => props.theme.colorway[1]};
      background-color: ${props => props.theme.background_content};
      border-color: ${props => props.theme.colorway[1]};
    }

    .ddk-container.ddk-container input:not([type]),
    .ddk-container.ddk-container input[type="email"],
    .ddk-container.ddk-container input[type="number"],
    .ddk-container.ddk-container input[type="search"],
    .ddk-container.ddk-container input[type="text"],
    .ddk-container.ddk-container input[type="tel"],
    .ddk-container.ddk-container input[type="url"],
    .ddk-container.ddk-container input[type="password"],
    .ddk-container.ddk-container textarea,
    .ddk-container.ddk-container select {
      background-color: ${props => props.theme.background_content};
      border-color: ${props => props.theme.border};
    }

    .ddk-container.ddk-container input[type="email"]:focus,
    .ddk-container.ddk-container input[type="number"]:focus,
    .ddk-container.ddk-container input[type="search"]:focus,
    .ddk-container.ddk-container input[type="text"]:focus,
    .ddk-container.ddk-container input[type="tel"]:focus,
    .ddk-container.ddk-container input[type="url"]:focus,
    .ddk-container.ddk-container input[type="password"]:focus,
    .ddk-container.ddk-container textarea:focus,
    .ddk-container.ddk-container select:focus,
    .ddk-container.ddk-container input[type="email"]:hover:enabled,
    .ddk-container.ddk-container input[type="number"]:hover:enabled,
    .ddk-container.ddk-container input[type="search"]:hover:enabled,
    .ddk-container.ddk-container input[type="text"]:hover:enabled,
    .ddk-container.ddk-container input[type="tel"]:hover:enabled,
    .ddk-container.ddk-container input[type="url"]:hover:enabled,
    .ddk-container.ddk-container input[type="password"]:hover:enabled,
    .ddk-container.ddk-container textarea:hover:enabled,
    .ddk-container.ddk-container select:hover:enabled {
      border-color: ${props => props.theme.colorway[1]}; }
      outline-color: 0;
    }

    .ddk-container.ddk-container th,
    .ddk-container.ddk-container td {
      border-bottom-color: ${props => props.theme.border};
    }

    .ddk-container.ddk-container hr {
      border-top-color: ${props => props.theme.border}; }

    /* CHECKBOXES and RADIO BUTTONS */

    .ddk-container.ddk-container input[type=checkbox],
    .ddk-container.ddk-container input[type=radio]   {
        border: 1px solid ${props => props.theme.border};
        color: ${props => props.theme.accent};
        width: 1em;
        height: 1em;
        outline: 0;
        padding: 0;
        float: left;
        -webkit-appearance: none;
        -moz-appearance: none;
        margin-right: 5px;
    }

    .ddk-container.ddk-container input[type=radio] {
        border-radius: 50%;
        position: relative;
    }

    .ddk-container.ddk-container input[type=radio]:checked:before {
        content: "";
        background-color: ${props => props.theme.accent};
        float: left;
        height: 10px;
        width: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .ddk-container.ddk-container input[type=checkbox]:checked:before {
        content: "✔";
        float: left;
        width: 1em;
        height: 1em;
        line-height: 1em;
        text-align: center;
    }

    .ddk-container.ddk-container input[type=radio]:checked:before {
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
    }

    /* Typography — Headers */
    .ddk-container.ddk-container .layout-header .layout-title .title,
    .ddk-container.ddk-container .layout-sidebar .layout-title .title {
        font-family: ${props => props.theme.font_family_header};
        font-size: ${props => props.theme.font_size_header}
    }
    .ddk-container.ddk-container h1,
    .ddk-container.ddk-container h2,
    .ddk-container.ddk-container h3,
    .ddk-container.ddk-container h4,
    .ddk-container.ddk-container h5,
    .ddk-container.ddk-container h6 {
        font-family: ${props => props.theme.font_family_headings};
    }

`
export default themedStyles;
