import React, {PureComponent, useCallback} from 'react';
import PropTypes from 'prop-types';
import {propOr} from 'ramda';

/* DDK React components */
import FullScreen from './FullScreen.react';
import Modal from './Modal.react';
import CopyText from '../_CopyText.react';

import {CardContext} from './Card.react'

/* Card icons */
import IconCardModal from './../icons/icon-card-modal.svg';
import IconCardFullscreen from './../icons/icon-card-fullscreen.svg';
import IconCardCopy from './../icons/icon-card-copy.svg';

class CardFooterInner extends React.Component {
    constructor(props) {
        super(props);
        if (this.props.copy || this.props.fullscreen || this.props.modal) {
            this.parentCard = this.props.card_ref().current;
            if (this.props.modal) {
                this.expand_modal_icon = (
                    <Modal
                        cardRef={this.parentCard}
                        width={(this.props.modal_config && this.props.modal_config.width) ? this.props.modal_config.width : null}
                        height={(this.props.modal_config && this.props.modal_config.height) ? this.props.modal_config.height : null}
                    >
                        <IconCardModal/>
                    </Modal>
                )
            }
        }
    }

    render() {
        /* card components
         * these are declared but only initialized if relevant arg is set
         */
        const {
            id,
            children,
            title,
            modal,
            modal_config,
            fullscreen,
            copy
        } = this.props;

        return (
            <footer
                className={`card-footer ${propOr('', 'className', this.props)}`}
                id={id}
            >
                {this.props.title &&
                    <b
                        className="card-footer--title"
                    >
                        {title}
                    </b>
                }
                {this.props.children &&
                    <div
                        style={{
                            'flex': 1
                        }}
                        className='control-in-card_container'
                     >
                        {children}
                     </div>
                 }
                 {copy &&
                     <CopyText
                         cardRef={this.parentCard}
                     >
                         <IconCardCopy/>
                     </CopyText>
                 }
                 {fullscreen &&
                     <FullScreen
                         cardRef={this.parentCard}
                     >
                          <IconCardFullscreen/>
                     </FullScreen>
                 }
                 {modal && this.expand_modal_icon}
             </footer>
        )
    }
}

// wrapper that allows Context usage in React lifecycle
// from: https://github.com/facebook/react/issues/12397#issuecomment-375501574
// this could be a functional component but for this.forceUpdate(), doesn't work with Dash x hooks

/**
 * A footer component designed to be the last child of a `Card` or `ControlCard`.
 * Takes `title` (string), `fullscreen`, and `modal` (bool) arguments, in addition
 * to `children[]` designed to contain Dash Core Components (DCC) controls
 * (e.g. `dcc.Dropdown`, `dcc.DatePickerRange`), or other components meant to be
 * displayed in the bottom of the card.
 *
 * Note that placing a string in `title` and a string in `children` will have a similar effect.
 * The only differences are that the title string will be bolded and that the children
 * property can include other components like controls (not just strings).
 *
 * **Example Usage**
 * ```
 * app.layout = ddk.App([
 *     ddk.Card(width=70,
 *         children=[
 *             # Allow the card to be expanded to fullscreen or modal
 *             # Set the expanded modal dimensions to 80%
 *             ddk.Graph(id="sample-graph-id", figure={
 *                  'data': [{
 *                    'x': [1, 2, 3, 4],
 *                    'y': [4, 1, 6, 9],
 *                    'line': {'shape': 'spline'}
 *                 }]
 *             }),
 *             ddk.CardFooter(
 *                dcc.DatePickerRange(
 *                    start_date=datetime.datetime(2019, 1, 1),
 *                    end_date=datetime.datetime(2020, 8, 1)
 *                ),
 *                title='String card footer',
 *              )
 *         ],
 *     ),
 * ])
 * ```
 */
class CardFooter extends React.Component {

    constructor(props) {
        super(props);

        this.needsUpdate = this.needsUpdate.bind(this);
    }

    needsUpdate(cardContext) {
        let {
            fullscreen,
            modal,
            copy
        } = this.props;

        this.parentCardRef = cardContext.getRef().current;

        return ((fullscreen || modal || copy) && !cardContext.getRef().current)
    }

    shouldComponentUpdate() {
        return !this.parentCardRef;
    }

    render() {

        const props = this.props;
        return (
            <CardContext.Consumer>
                {(cardContext) => {
                    return (
                        <React.Fragment>
                            <React.Fragment key={(this.needsUpdate(cardContext) ? this.forceUpdate() : '')} />
                            { !this.needsUpdate(cardContext) && <CardFooterInner {...props} card_ref={cardContext.getRef} /> }
                        </React.Fragment>
                    )
                }}
            </CardContext.Consumer>
        )
    }
};


CardFooterInner.defaultProps = {
}

CardFooter.defaultProps = {
}

CardFooter.propTypes = {
    /**
     * The ID of this component, used to identify Dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string,
    /**
     * The list of components that are children of the CardFooter container.
     * These children should be Dash Core Components (DCC) controls
     * (e.g. `dcc.Dropdown`, `dcc.DatePickerRange`),
     * or other components meant to be displayed in the bottom of the card.
     */
    children: PropTypes.node,

    /**
     * string or Dash component; optional
     */
    title: PropTypes.string,

    /**
     * Displays an icon that allows the card to be expanded to a modal.
     */
    modal: PropTypes.bool,

    /**
     * Displays an icon that allows the card's innerText to be copied
     * to the clipboard.
     */
    copy: PropTypes.bool,

    /**
     * Object that takes 'width' and 'height' arguments to define modal dimensions
     * Width or height can either be a string or a num N that gets converted to N%
     */
    modal_config: PropTypes.exact({
        width: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        height: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ])
    }),

    /**
     * Displays an icon that allows the card to be expanded to a modal.
     */
    fullscreen: PropTypes.bool,

    /**
     * Optional additional CSS styles.
     * - If `width`, `padding`, or `margin` are supplied within `style`,
     * then this will override the component-level `width`, `padding`, or `margin`.
     */
    style: PropTypes.object,

    /**
     * Optional user-defined CSS class for the CardFooter container.
     */
    className: PropTypes.string
}

CardFooterInner.propTypes = {
    /**
     * The ID passed into the inner component.
     */
    id: PropTypes.string,

    /**
     * The list of components that are children of the CardFooter container.
     */
    children: PropTypes.node,

    /**
     * string or Dash component; optional
     */
    title: PropTypes.string,

    /**
     * Displays an icon that allows the card to be expanded to a modal.
     */
    modal: PropTypes.bool,

    /**
     * Displays an icon that allows the card's innerText to be copied
     * to the clipboard.
     */
    copy: PropTypes.bool,

    card_context: PropTypes.any,

    /**
     * Object that takes 'width' and 'height' arguments to define modal dimensions
     * Width or height can either be a string or a num N that gets converted to N%
     */
    modal_config: PropTypes.shape({
        width: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        height: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ])
    }),

    /**
     * Displays an icon that allows the card to be expanded to a modal.
     */
    fullscreen: PropTypes.bool,

    /**
     * Optional additional CSS styles.
     * - If `width`, `padding`, or `margin` are supplied within `style`,
     * then this will override the component-level `width`, `padding`, or `margin`.
     */
    style: PropTypes.object,

    /**
     * Optional user-defined CSS class for the CardFooter container.
     */
    className: PropTypes.string
}

export default CardFooter;
