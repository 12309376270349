import { css, objectToStyles } from '../utils.js';
import chroma from 'chroma-js';
import * as R          from 'ramda';

const themedStyles = css`
	.ddk-container.ddk-container .button,
	.ddk-container.ddk-container button,
	.ddk-container.ddk-container input[type="submit"],
	.ddk-container.ddk-container input[type="reset"],
	.ddk-container.ddk-container input[type="button"] {
        border-radius: 0;
    }

    .ddk-container.ddk-container button[disabled] {
	    cursor: not-allowed;
	}

	.ddk-container.ddk-container input:not([type]),
	.ddk-container.ddk-container input[type="email"],
	.ddk-container.ddk-container input[type="number"],
	.ddk-container.ddk-container input[type="search"],
	.ddk-container.ddk-container input[type="text"],
	.ddk-container.ddk-container input[type="tel"],
	.ddk-container.ddk-container input[type="url"],
	.ddk-container.ddk-container input[type="password"],
	.ddk-container.ddk-container textarea,
	.ddk-container.ddk-container select {
		${props => objectToStyles(props.theme.border_style)}
	}

	.ddk-container.ddk-container input:not([type]):focus,
	.ddk-container.ddk-container input[type="email"]:focus,
	.ddk-container.ddk-container input[type="number"]:focus,
	.ddk-container.ddk-container input[type="search"]:focus,
	.ddk-container.ddk-container input[type="text"]:focus,
	.ddk-container.ddk-container input[type="tel"]:focus,
	.ddk-container.ddk-container input[type="url"]:focus,
	.ddk-container.ddk-container input[type="password"]:focus {
		outline: 'transparent';
	}

	.ddk-container.ddk-container input:disabled {
	    color: ${props => chroma(props.theme.text).alpha(0.8).css()};
	    background-color: ${props => chroma(props.theme.background_content).alpha(0.8).css()};
		border-bottom: none;
	}

	.ddk-container.ddk-container label {
        margin-left: 5px;
        display: flex;
        align-items: center;
	}
`
export default themedStyles;
