import React, {Component, useContext} from 'react';
import PropTypes from 'prop-types';
import {merge, mergeAll, propOr} from 'ramda';


/* DDK React components */
import FullScreen from './FullScreen.react';
import Modal from './Modal.react';
import CopyText from '../_CopyText.react';
import Block from './Block.react';
import CardHeader from './CardHeader.react';

/* Card icons */
import icon_card_modal from '../icons/icon-card-modal.svg';
import icon_card_fullscreen from '../icons/icon-card-fullscreen.svg';
import icon_card_copy from '../icons/icon-card-copy.svg';

import {relative_dimension, defaultToTheme} from './../utils.js';

import {ControlCardContext} from './ControlCard.react';

// instantiate + export context
// imported in Control
export const CardContext = React.createContext();

/**
 * A layout component used for easily arranging groups of elements.
 *
 * **Example Usage**
 * ```
 * app.layout = ddk.App([
 *     ddk.Card(
 *         width=30,
 *         children=[
 *             # this content takes up 30% of the screen's width
 *         ]
 *     ),
 *     ddk.Card(
 *         width=70,
 *         children=[
 *             # this content takes up 70% of the screen's width
 *         ]
 *     ),
 *     ddk.Card(
 *         width=50,
 *         children=[
 *              # this content wraps onto the next line
 *              # (below the cards above) and takes up 50%
 *              # of the screen's width
 *         ]
 *     )
 * ])
 * ```
 */
class Card extends Component {
    constructor(props) {
        super(props);
        this.cardRef = React.createRef();

        this.bindEvents = this.bindEvents.bind(this);
        this.handleEvent = this.handleEvent.bind(this);
        this.state = {
            theme: window.dashTheme
        };
    }

    bindEvents() {
        window.addEventListener(
            'dash-theme-update',
            this
        );
    }

    /*
     * handleEvent is a special method that allows this class to
     * be passed (as `this`) in place of an event handler. This is used in conjunction with
     * `window.addEventListener` that listens to the `dash-theme-update`. See more:
     *    - https://developer.mozilla.org/en-US/docs/Web/API/EventListener/handleEvent)
     *    - https://metafizzy.co/blog/this-in-event-listeners/
     */
    handleEvent() {
        const theme = window.dashTheme;
        this.setState({theme: window.dashTheme});
    }

    componentWillUnmount() {
        if (this.eventEmitter) {
            this.eventEmitter.removeAllListeners();
        }
    }

    componentDidMount() {
        this.bindEvents();
        this.handleEvent();
    }

    render() {
        const {
            id,
            children,
            type,
            shadow_weight,
            card_hover,
            rounded,
            margin,
            padding,
            border_width,
            border_style,
            border_color,
            border_radius,
            box_shadow,
            padding_inner,
        } = this.props;
        let {width} = this.props;

        const {theme} = this.state;

        if (width) {
            width = relative_dimension(parseFloat(width, 10), parseFloat(defaultToTheme(margin, 'card_margin'), 10))
        }

        /* initial card class */
        let card_class = 'card';

        if (card_hover) {
            card_class += ' card-box-shadow-hover';
        }

        /* initial card content class */
        let content_class = 'card--content'

        /* initial card content inline style */
        let content_style = {
            'padding': padding_inner,
        }

        /* initial card inline style */

        let card_style = {
            'shadow': {
                'light': {'boxShadow': '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                          'transition': 'all 0.3s cubic-bezier(.25,.8,.25,1)'},
                'medium': {'boxShadow': '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)'},
                'heavy': {'boxShadow': '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)'},
                [undefined]: {}, 
                [null]: {}
            }[shadow_weight],
            'color': {
                'borderColor': theme.accent,
                'borderStyle': 'solid',
                'borderWidth': '1px'
            },
            'simple-border': {
                'border': `thin ${theme.border} solid`,
                'boxShadow': 'none'
            },
            'flat': {'boxShadow': 'none'}
        }[type]

        /* conditional border styles */
        if (rounded && !border_radius) {
            card_style.borderRadius = '5px';
        }

        /* merge conditional styles from props */
        const style = mergeAll([
            {
                padding: padding,
                margin: margin,
                borderWidth: border_width,
                borderStyle: border_style,
                borderColor: border_color,
                borderRadius: border_radius,
                boxShadow: box_shadow,
                width: width
            },
            card_style,
            propOr({}, 'style', this.props)
        ]);

        const getRef = () => {
            return this.cardRef;
        }

        return (
            <Block
                style={style}
                className={`${card_class} ${propOr('', 'className', this.props)}`}
                dataUserStyle={this.props.style}
                width={width}
                padding={padding}
                margin={margin}
                id={id}
                ref={this.cardRef}
            >
                <ControlCardContext.Consumer>
                    { context => {
                        if (context) {
                            content_class += ' ' + context.control_card_class;
                            content_style = merge(content_style, context.control_card_style)
                        }
                        return (
                            <CardContext.Provider value={{'getRef': getRef}}>
                                <div
                                    className={content_class}
                                    style={content_style}
                                    ref={this.cardRef}
                                    getRef={getRef}
                                >
                                    {children}
                                </div>
                            </CardContext.Provider>
                        )
                    }}
                </ControlCardContext.Consumer>
            </Block>
        )
    }
}

Card.defaultProps = {
    type: 'shadow',
    width: 100,
    padding_inner: '5px'
}

Card.propTypes = {
    /**
     * The ID of this component, used to identify Dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string,

    /**
     * The list of components that are children of the Card container.
     */
    children: PropTypes.node,

    /**
     * The appearance of the card's border.
     */
    type: PropTypes.oneOf(['shadow', 'color', 'simple-border', 'flat']),

    /**
     * The appearance of the card's box-shadow, if set.
     */
    shadow_weight: PropTypes.oneOf(['light', 'medium', 'heavy']),

    /**
     * Add a box-shadow to the card on hover.
     */
    card_hover: PropTypes.bool,

    /**
     * Object that takes 'width' and 'height' arguments to define modal dimensions
     * Width or height can either be a string or a num N that gets converted to N%
     */
    modal_config: PropTypes.exact({
        width: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        height: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ])
    }),

    /**
     * Applies a border-radius to the card's border.
     */
     rounded: PropTypes.bool,

    /**
     * Number between 0 and 100 representing the width of the component
     * with respect to its parent.
     * - This is a percentage by default: `25` means take up 25% of the space.
     * - Unless <1, in which it represents a decimal: 0.25 is the same as 25
     *
     * Note that these units are different than the CSS `style` units where
     * `style={'width': 25}` means _25 pixels_, not 25%.
     */
    width: PropTypes.number,

    /**
     * Space (in pixels) surrounding the card.
     * Overrides theme.card_margin.
     */
    margin: PropTypes.number,

    /**
     * Space (in pixels) on the inside of the card, between the border
     * and the edge of the content.
     * Overrides theme.card_padding.
     */
    padding: PropTypes.number,

    /**
     * Space (in pixels) on the inside of the card content, between the
     * bounding box of the content and the content itself.
     */
    padding_inner: PropTypes.string,

    /**
     * The box shadow(s) applied to the card. Overrides theme.card_box_shadow.
     */
    box_shadow: PropTypes.string,

    /**
     * The border width applied to the card. Overrides theme.card_border.width.
     */
    border_width: PropTypes.string,

    /**
     * The border width applied to the card. Overrides theme.card_border.style.
     */
    border_style: PropTypes.string,

    /**
     * The border color applied to the card. Overrides theme.card_border.color.
     */
    border_color: PropTypes.string,

    /**
     * The border radius applied to the card. Overrides theme.card_border.radius.
     */
    border_radius: PropTypes.string,

    /**
     * Optional additional CSS styles.
     * - If `width`, `padding`, or `margin` are supplied within `style`,
     * then this will override the component-level `width`, `padding`, or `margin`.
     */
    style: PropTypes.object,

    /**
     * Optional user-defined CSS class for the Card container
     */
    className: PropTypes.string
}

export default Card;
