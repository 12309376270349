import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Graph from './Graph.react.generated';
import Icon from './Icon.react.js';
import Block from './Block.react.js';
import { merge } from 'ramda';
// includes the theme context to have access to theme variables in DataCard component

import {ReportContext} from './Report.react'

/**
 * This component will render a styled DataCard with a label, large number, or
 * sublabel. Style includes handle (with optional icon) or spark trace
 * if data is provided.
 */
class DataCard extends Component {
    constructor(props) {
        super(props);
        this.bindEvents = this.bindEvents.bind(this);
        this.handleEvent = this.handleEvent.bind(this);
        this.state = {
            theme: window.dashTheme
        };
    }

    bindEvents() {
        window.addEventListener(
            'dash-theme-update',
            this
        );
    }

    /*
     * handleEvent is a special method that allows this class to
     * be passed (as `this`) in place of an event handler. This is used in conjunction with
     * `window.addEventListener` that listens to the `dash-theme-update`. See more:
     *    - https://developer.mozilla.org/en-US/docs/Web/API/EventListener/handleEvent)
     *    - https://metafizzy.co/blog/this-in-event-listeners/
     */
    handleEvent() {
        this.setState({theme: window.dashTheme});
    }

    componentWillUnmount() {
        if (this.eventEmitter) {
            this.eventEmitter.removeAllListeners();
        }
    }

    componentDidMount() {
        this.bindEvents();
        this.handleEvent();
    }

    render() {
        const {
            id,
            value,
            label,
            sub,
            color,
            style,
            trace_x,
            trace_y,
            trace_text,
            icon,
            background_color,
            width,
            margin,
            padding,
            border,
            box_shadow
        } = this.props;

        const theme = this.state.theme;

        const getStyles = (context) => {
            return {
                StyledDataCard: merge({
                    'margin': theme.card_margin,
                    'borderWidth': theme.card_border.width,
                    'borderStyle': theme.card_border.style,
                    'borderColor': theme.card_border.color,
                    'borderRadius': theme.card_border.radius,
                    'outlineWidth': theme.card_outline.width,
                    'outlineStyle': theme.card_outline.style,
                    'outlineColor': theme.card_outline.color,
                    'boxShadow': theme.card_box_shadow,
                    'fontFamily': `${
                        context && context.inReportContext
                          ? theme.report_font_family
                          : theme.font_family
                    }`,
                    'color': `${
                        context && context.inReportContext
                          ? theme.report_text
                          : theme.text
                     }`,
                    'backgroundColor': `${
                        trace_y && background_color ?
                            background_color :
                            context && context.inReportContext
                              ? theme.report_background_content
                              : theme.card_background_color
                    }`
                }, style),
                Handle: {
                    'backgroundColor': `${color || theme.accent}`,
                    'borderRadius': theme.card_border.radius
                      ? `calc(${theme.card_border.radius} * 2/3)`
                      : theme.card_border.radius,
                    'borderTopLeftRadius': theme.card_border.radius && '0px',
                    'borderBottomLeftRadius': theme.card_border.radius && '0px'
                },
                DataCardContent: {
                    'padding': theme.card_padding,
                    'backgroundColor': `${
                        context && context.inReportContext
                          ? theme.report_background_content
                          : theme.background_content
                        }`
                }
            }
        }

        const axis = {'ticks': '', 'showticklabels': false,
                      'showline': false, 'showgrid': false};
        const graphMargin = {'l': 0, 'r': 0, 't': 0, 'b': 0};

        const dataCardNumbers = (
            <div className="DataCardNumbers">
                <div className="Value" title={value}>
                    {value}
                </div>
                <div className="SubValue">
                    {sub}
                </div>
            </div>
        );

        let dataCard;

        if (trace_y) {

            dataCard = (
                <ReportContext.Consumer>
                    { context => (
                        <div className="StyledDataCard"
                            id={id} 
                            style={getStyles(context).StyledDataCard}
                        >

                            <div className="DataCardContentGraph" color={background_color}>
                                {dataCardNumbers}
                                <div className="Label">{label}</div>
                            </div>

                            {/* In flex, margin-top auto will align the graph
                                with the _bottom_ of the card.
                                This is only relevant when a card is taller than usual
                                because its in a row next to another card whose
                                text is overflowing.
                                Without this, the graph would leave some empty space
                                between its bottom edge and the card.
                                With this, the graph is tucked up against the bottom edge*/}
                            <Graph figure={{'data':[{'x': trace_x,
                                                     'y': trace_y,
                                                     'text': trace_text,
                                                     'mode':'lines',
                                                     'shape': 'spline',
                                                     'line': {'width':2, 'color': background_color ? 'white' : (color || theme.accent)},
                                                     'fill': 'tozeroy'}],
                                            'layout': {
                                                'margin': graphMargin,
                                                'height': 140,
                                                'xaxis': axis,
                                                'yaxis': axis,
                                                'plot_bgcolor': background_color
                                            }
                                    }}
                                    config={{'displayModeBar': false}}
                                    style={{'marginTop': 'auto'}}
                            >
                            </Graph>

                        </div>
                    )}
                </ReportContext.Consumer>
            );

        } else {

            dataCard = (
                <ReportContext.Consumer>
                    { context => (
                        <div
                            className="StyledDataCard"
                            id={id}
                            style={getStyles(context).StyledDataCard}
                        >
                            <div className="HandleWrapper">

                                <div
                                    className="DataCardContent"
                                    style={getStyles(context).DataCardContent}
                                >
                                    {dataCardNumbers}
                                    <div className="Label">{label}</div>
                                </div>

                                <div className="Handle"
                                    style={getStyles(context).Handle}
                                    color={color}
                                    children={icon && (
                                      <Icon
                                          style={{'position':'absolute',
                                                  'top': '50%',
                                                  'left':'50%',
                                                  'transform': 'translate(-50%, -50%)'}}
                                          icon_name={icon}
                                          icon_category='solid'
                                          icon_color={
                                            context && context.inReportContext
                                              ? theme.report_background_content
                                              : theme.background_content
                                          }>
                                      </Icon>)}
                                >
                                </div>

                            </div>
                        </div>
                    )}
                </ReportContext.Consumer>
            )

        }

        return (
            <Block
                width={width}
                margin={margin}
            >
                {dataCard}
            </Block>
        );

    }
}

DataCard.contextType = ReportContext

DataCard.defaultProps = {
    width: 25,
}


DataCard.propTypes = {
    /**
     * The ID of this component, used to identify Dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string,

    /**
     * The large number
     */
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),

    /**
     * The textual label that describes the label
     */
    label: PropTypes.string,

    /**
     * A smaller number to accompany `value`
     */
    sub: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),

    /**
     * Color modifies:
     * - The background color of the "handle". If not supplied, then this will be the accent color.
     * - If `trace_y` is supplied, then `color` is the color of the
     *   graph's line (unless `background_color` is supplied, in which case
     *   the trace line will be white). If `color` and `background_color`
     *   aren't supplied, then the color of the line will be the theme's accent
     *   color.
     */
    color: PropTypes.string,

    /**
     * The `x` data in the graph.
     */
    trace_x: PropTypes.array,

    /**
     * The `y` data in the graph.
     */
    trace_y: PropTypes.array,

    /**
     * The `text` data in the graph (appears on hover)
     */
    trace_text: PropTypes.array,

    /**
     * The font awesome icon name.
     * This is the same as the `ddk.Icon` `icon_name` property.
     */
    icon: PropTypes.string,

    /**
     * `background_color` is only used if `trace_y` is supplied.
     * In which case, it will be the background color of the entire DataCard
     */
    background_color: PropTypes.string,

    /**
     * The color of the text. If this isn't supplied, then
     * it will default to the theme's text color.
     */
    text_color: PropTypes.string,

    /**
     * Space (in pixels) surrounding the DataCard.
     * Overrides theme.card_header_margin.
     */
    margin: PropTypes.number,

    /**
     * Space (in pixels) on the inside of the DataCard, between the border
     * and the edge of the content.
     * Overrides theme.card_header_padding.
     */
    padding: PropTypes.number,

    /**
     * The box shadow(s) applied to the DataCard. Overrides theme.card_header_box_shadow.
     */
    box_shadow: PropTypes.string,

    /**
     * The border width applied to the DataCard. Overrides theme.card_header_border.width.
     */
    border_width: PropTypes.string,

    /**
     * The border style applied to the DataCard. Overrides theme.card_header_border.style.
     */
    border_style: PropTypes.string,

    /**
     * The border color applied to the DataCard. Overrides theme.card_header_border.color.
     */
    border_color: PropTypes.string,

    /**
     * The border radius applied to the DataCard. Overrides theme.card_header_border.radius.
     */
    border_radius: PropTypes.string,

    /**
     * Style overrides to the outermost container
     */
    style: PropTypes.object,

    /**
     * The width (in percentage) of the component with respect to its parent.
     * This is the same type of unit that is used in
     * `Block`, `Card`, & `ControlCard`.
     */
    width: PropTypes.number,

    /**
     * Space (in pixels) surrounding the `DataCard`.
     */
    margin: PropTypes.number,

    /**
     * Dash-assigned callback that gets fired when the value changes.
     */
    setProps: PropTypes.any,
}

export default DataCard;
