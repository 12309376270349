import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propOr, omit, type} from 'ramda';
import { appendStyle, css } from '../utils';


/**
 * A styled div meant to be placed in a `ddk.Header` or a `ddk.Sidebar`
 */
export default class Title extends Component {

    constructor(props) {
        super(props)
        this.state = {
            lineHeight: 'initial'
        }
        this.titleRef = React.createRef();
    }

    componentDidMount() {
        const node = this.titleRef.current;
        const lineHeight = (
            node.parentElement.classList.contains('layout-header') &&
            node.parentElement.clientHeight
        );
        /*
         * If there's a parent, use its dimensions for line-height and don't reset on a re-render.
         * Otherwise, use the default this.state.lineHeight
        */
        if (lineHeight) {
            this.setState({ lineHeight: lineHeight});
        }
    }

    render() {
      const { children, allow_hidden_in_header, ...rest } = this.props;
      return (
        <div
            ref={this.titleRef}
            className={`layout-title ${propOr('', 'className', rest)}`}
            {...omit(['className'], rest)}
        >
        {children &&
            <div
                className="title"
                style={{
                    whiteSpace: (
                        allow_hidden_in_header && this.state.lineHeight !== 'initial' ?
                        'nowrap' : 'normal'
                    ),
                    lineHeight: (typeof(this.state.lineHeight) === 'number' ? `${this.state.lineHeight}px` : this.state.lineHeight)
                }}
            >
                {children}
            </div>
        }
        </div>
      );
    }
};

Title.defaultProps = { allow_hidden_in_header: true }

Title.propTypes = {
    /**
     * The ID of this component, used to identify Dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string,

    /**
     * The title's content. This is usually just a string.
     */
    children: PropTypes.node,

    /**
     * If `True`, then the title will disappear on smaller screens when
     * there isn't enough room.
     * If `False`, then the title will disappear _word-by-word_ when there
     * isn't enough room.
     */
    allow_hidden_in_header: PropTypes.bool,

    /**
     * The style of the outermost div of the Title component
     */
    style: PropTypes.object,
    /**
     * Additional class names for the outermost div of the Title component
     */
    className: PropTypes.string
};
