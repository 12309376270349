import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {merge, propOr} from 'ramda';

/* DDK React components */
import Card from './Card.react';

// instantiate + export context
// imported in Control
export const ControlItemContext = React.createContext();

export const ControlCardContext = React.createContext();
/**
 * A layout component used for easily arranging groups of
 * controls (Dash Core Components) wrapped in ControlItems.
 *
 * **Example Usage**
 * ```
 * app.layout = ddk.App([
 *     ddk.ControlCard(
 *         children=[
 *               ddk.ControlItem(
 *                   dcc.Slider(
 *                       min=0,
 *                       max=10,
 *                       marks={
 *                           0: '0',
 *                           5: '5',
 *                           10: '10'
 *                       },
 *                       value=5
 *                   ),
 *                   label='Thrusters'
 *               ),
 *               ddk.ControlItem(
 *                   dcc.Input(
 *                       value=50,
 *                       type='number'
 *                   ),
 *                   label='Power'
 *               )
 *           ],
 *         orientation='horizontal',
 *         label_position='left',
 *     )
 * ])
 * ```
 */

const ControlCard = (props) => {
    const {
        id,
        children,
        orientation,
        label_position,
        label_text_alignment,
        label_style,
        control_position,
        wrap,
        style,
    } = props;

    /*
      props implicitly passed to Control children via Context
      used to calculate in-line control styles
    */
    const control_style_context = {
        orientation: orientation,
        label_position: label_position,
        label_text_alignment: label_text_alignment,
        label_style: label_style,
        wrap: wrap
    }

    const control_card_style = {
        'flex-wrap': (wrap && orientation === 'horizontal') ? 'wrap': 'nowrap',
        'flex-direction': orientation === 'vertical' ? 'column' : 'initial'
    }

    const control_card_class = 'controls ' + orientation + ' ' + control_position;

    return (
        <ControlCardContext.Provider value={{
            'control_card_class': control_card_class,
            'control_card_style': control_card_style
        }}>
            <Card
                {...props}
                id={id}
                style={style}
                control_card_class={control_card_class}
                control_card_style={control_card_style}
                className={`${propOr('', 'className', props)}`}
            >
                <ControlItemContext.Provider value={control_style_context}>
                    {children}
                </ControlItemContext.Provider>
            </Card>
        </ControlCardContext.Provider>
    )
}

ControlCard.defaultProps = {
    orientation: 'vertical',
    label_position: 'top',
    label_text_alignment: 'left',
    control_position: 'left',
    padding: 5,
    wrap: true,
    width: 100,
}

ControlCard.propTypes = {
    /**
     * The ID of this component, used to identify Dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string,

    /**
     * The list of components, most commonly `ddk.ControlItem` items,
     * that are children of the ControlCard container.
     */
    children: PropTypes.node,

    /**
     * The appearance of the card's border.
     */
    type: PropTypes.oneOf(['shadow', 'color', 'simple-border', 'flat']),

    /**
     * The appearance of the card's box-shadow, if set.
     */
    shadow_weight: PropTypes.oneOf(['light', 'medium', 'heavy']),

    /**
     * Add a box-shadow to the card on hover.
     */
    card_hover: PropTypes.bool,

    /* Props passed in from a ControlCard */

    /**
     * Applies a border-radius to the card's border.
     */
     rounded: PropTypes.bool,

    /**
     * Number between 0 and 100 representing the width of the component
     * with respect to its parent.
     * - This is a percentage by default: `25` means take up 25% of the space.
     * - Unless <1, in which it represents a decimal: 0.25 is the same as 25
     *
     * Note that these units are different than the CSS `style` units where
     * `style={'width': 25}` means _25 pixels_, not 25%.
     */
    width: PropTypes.number,

    /**
     * Space (in pixels) surrounding the card.
     */
    margin: PropTypes.number,

    /* end Card propTypes */


    /**
     * The orientation of the set of controls.
     */
    orientation: PropTypes.oneOf(['vertical', 'horizontal']),

    /**
     * The positon of the label with respect to the control.
     */
    label_position: PropTypes.oneOf(['top', 'left', 'bottom', 'right']),

    /**
     * The horizontal label text alignment.
     */
    label_text_alignment: PropTypes.oneOf(['left', 'right', 'center']),

    /**
     * Optional additional label CSS styles.
     */
    label_style: PropTypes.object,

    /**
     * The control alignment relative to the ControlCard container.
     */
    control_position: PropTypes.oneOf(['left', 'right', 'center', 'top']),

    /**
     * The padding of (i.e. whitespace around) each individual control. Takes `%` int or string `Npx, Nem`, etc. values
     */
    padding: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),

    /**
     * Given a sum of control widths of horizontally-oriented controls >=100%, whether or not they
     * should wrap onto the next line or stay in a single line.
     * By default, if there are more than 5 (20% default width * 5 = 100%) controls, they will wrap.
     */
    wrap: PropTypes.bool,

    /**
     * Optional additional CSS styles.
     * - If `width`, `padding`, or `margin` are supplied within `style`,
     * then this will override the component-level `width`, `padding`, or `margin`.
     */
    style: PropTypes.object,

    /**
     * Optional user-defined CSS class for the Block container.
     */
    className: PropTypes.string
};

export default ControlCard;
