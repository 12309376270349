import {CLASSNAMES} from './constants';
import {contains, defaultTo, keys, identity, memoizeWith} from 'ramda';
import chroma from 'chroma-js';

// TODO Memoize
export const isChildOf = (domElement, className) => {
    let parentElement = domElement.parentElement;
    // TODO - caniuse?
    while (parentElement.tagName !== 'BODY') {
        if (parentElement.classList.contains(className)) {
            return true;
        }
        parentElement = parentElement.parentElement;
    }
    return false;
};

export const relative_dimension = (width, margin) => {
    let derived_width = width;
    let derived_margin = margin;
    if (!derived_margin) {
        derived_margin = 0;
    }
    if (typeof derived_width === 'string') {
        if (derived_width.includes('px') || derived_width.includes('%')) {
            return 'calc(' + derived_width + ' - ' + (derived_margin * 2) + 'px)';
        }
        else if (derived_width === 'auto') {
            return 'auto';
        }
        return 'initial';
    }
    else {
        if (!derived_width) {
            derived_width = 20;
        }
        else if (derived_width <= 1) {
            derived_width *= 100;
        }
        return 'calc(' + derived_width + '% - ' + (derived_margin * 2) + 'px)';
    }
}

export function capitalizeString(str) {
    if (typeof str === 'string') {
        if (str.length === 0) {
            return str;
        }
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
}

/*
 * this is better than a RegEx parser because it
 * just lets the browser interpret the individual
 * style properties
 */
export function parseCSSText(attr, value) {
    var el = document.createElement("span");
    el.style[attr] = value;

    return el.style;
}

export function getValueFromCSSText(attr, shorthandAttr, shorthandValue) {
    if (shorthandAttr === 'borderTopLeftRadius') {
        debugger;
    }
    return parseCSSText(shorthandAttr, shorthandValue)[attr]
}

export function getNewShorthand(attr, value, shorthandAttr, shorthandValue) {
    var el = document.createElement("span")
    el.style[shorthandAttr] = shorthandValue;
    el.style[attr] = value;

    return el.style[shorthandAttr];
}

export function isValidColor(color) {
    return chroma.valid(color);
}

export function css(strings, ...keys) {
  return (function(props) {
    let result = [strings[0]];
    for (let i = 0; i < keys.length; i++) {
        // key is a function like props => props.theme.accent
        let evaluated_value = keys[i](props);
        result.push(evaluated_value, strings[i + 1]);
    }
    const cssString = result.join('');
    if (contains('//', cssString)) {
        throw new Error('// found in ' + cssString);
    }
    return cssString;
  });
}

/**
 * from https://codepen.io/gskinner/pen/BVEzox
 */
export function templateLiteralDedent(str) {
    str = str.replace(/^\n/, "");
    let match = str.match(/^\s+/);
    return match ? str.replace(new RegExp("^"+match[0], "gm"), "") : str;
}

export function appendStyle(id, css, documentRef) {
    var currentDoc = documentRef || window.document
    try{
        currentDoc.getElementById(id).remove();
    } catch(e) {}
    const node = currentDoc.createElement('style');
    node.textContent = css;
    node.type = 'text/css';
    node.id = id;
    currentDoc.head.appendChild(node);
}

function camelCaseToHyphen(string) {
    // taking from https://stackoverflow.com/questions/6660977/convert-hyphens-to-camel-case-camelcase#comment14968137_6661012
    return string.replace(/([a-z][A-Z])/g, function (g) {
        return g[0] + '-' + g[1].toLowerCase()
    });
}

export function objectToStyles(object) {
    let style = '';
    const objectKeys = keys(object);
    for (let i = 0; i < objectKeys.length; i++) {
        style += `${camelCaseToHyphen(objectKeys[i])}: ${object[objectKeys[i]]};\n`;
    }
    return style;
}

// more robust test of falsy values
// using `prop || default` fails on valid but falsy e.g. prop=0 values
export function defaultToTheme(prop, theme_prop) {
    const theme = window.dashTheme;
    return prop === undefined || prop === null
      ? theme[theme_prop]
      : prop
}

/**
* ## decaf.extend(dest, src ...src) : dest (chainable)
*
* From decaf.js (https://github.com/decafjs/decaf/blob/master/builtins/decaf.js),
* used because it handles getters and setters without evaluating them
*
* Modified by @wbrgss to allow `configurable` properties and recursive deep merge.
*
* Merge one or more Objects to a destination object
*
* This can be used to extend a JavaScript class or prototype.  It is heavily used throughout the DecafJS source
* code.
*
* This function is smart enough to merge getter and setter functions rather than the values those get or set.  Unlike
* AngularJS and jQuery implementations.
*
* #### Arguments:
*  - {object} dest - the object that will be the result of the object merges.
*  - {object} src... - one or more objects to be merged into the result (dest) object
*
* #### Returns:
*  - {object} - dest - the destination/result object
*
* @method extend
* @param {Object} me - the destination object
* @param {Object...} objects - the objects to merge
* @returns {Object} the merged (destination) object.
*/
export function extend(me) {
    var args = Array.prototype.slice.call(arguments, 1);
    _each(args, function ( o ) {
        for (var key in o) {
            if ( o.hasOwnProperty(key) ) {
                var desc = Object.getOwnPropertyDescriptor(o, key),
                    g = desc.get, s = desc.set;
                if ( g || s ) {
                    Object.defineProperty(me, key, { get : g, set : s, enumerable : true, configurable : true });
                }
                else if (Array.isArray(o[key])) {
                    // if the new value is an array, do a straight override assignment
                    me[key] = o[key];
                }
                else if (typeof o[key] === "object") {
                    if (!me[key] || typeof me[key] !== "object") {
                        me[key] = o[key];
                    }
                    extend(me[key], o[key]);
                }
                else {
                    me[key] = o[key];
                }
            }
        }
    });
    return me;
}

/**
* ## decaf.each(o, fn)
*
* Iterate over an object or array, calling the specified function for each member.
*
* #### Arguments:
*  - o - the object to iterate over
*  - fn - the function to be called with each member of the object
*
* The called function has the following signature:
*
*     function callback(item, index) {
*       // use item
*     }
*
* If the function returns false, the iteration will not continue.
*
* @method each
* @param {Object|Array} o - object or array to iterate over
* @param {Function} fn - function to be called for each
*/
function _each (o, fn) {
    for ( var key in o ) {
        if ( o.hasOwnProperty && o.hasOwnProperty(key) ) {
            if ( fn.call(o, o[ key ], key, o) === false ) {
                return;
            }
        }
    }
}
