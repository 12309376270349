import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propOr} from 'ramda';

import {appendStyle, objectToStyles} from '../utils';
import { css } from '../utils.js';

const row_components =  css`
   .ddk-container
    .row > .card .card-header,
    .ddk-container
    .row > .card .card-footer {
        min-height: ${state => state.controlHeight + 'px'};
    }
`

const calculateControlHeight = (list) => Math.max.apply(Math, list.map((c) => { return c.offsetHeight + 12; }))

/**
 * The Row component is intended to wrap `Block` or `Card` components.
 * It will enforce equal height in these components.
 *
 * `Block` and `Card` do not _need_ to be wrapped in a `Row`: only wrap
 * them in a `Row` if you want to force their contents to have equal height.
 *
 * **Example Usage**
 *
 * ```
 * ddk.Row([
 *     ddk.Card(
 *         width=50,
 *         children=ddk.Graph(figure={
 *             'data': [{
 *                 'x': [1, 2, 3, 4],
 *                 'y': [5, 4, 3, 6],
 *                 'line': {'shape': 'spline'}
 *             }],
 *             'layout': {'height': 300}
 *         })
 *     ),
 *
 *     ddk.Card(
 *         width=50,
 *         children='''
 *         Sed ut perspiciatis unde omnis iste natus
 *         voluptatem accusantium doloremque laudantium,
 *         totam rem aperiam, eaque ipsa quae ab illo
 *         inventore veritatis et quasi architecto
 *         beatae vitae dicta sunt explicabo.
 *         '''
 *     )
 * ])
 * ```
 *
 * See more examples in the [Blocks, Cards, and Rows](/blocks) chapter.
 */
class Row extends Component {
    constructor(props) {
        super(props);
        this.state = {
            controlHeight: 0
        }
        this.updateControlHeight = this.updateControlHeight.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateControlHeight, true);
        // we need an extra "tick" for timing purposes
        setTimeout(() => { this.updateControlHeight(); }, 1);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateControlHeight, true);
    }

    updateControlHeight() {
        const nodeList = document.querySelectorAll('.row .control-in-card_container .Select, .row .control-in-card_container .DateRangePicker, .row .control-in-card_container .SingleDatePicker .row .control-in-card_container .rc-slider, .row .control-in-card_container input');
        const height = calculateControlHeight([...nodeList]);
        this.setState({controlHeight: height});
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.controlHeight !== prevState.controlHeight) {
            // Only update the relevant stylesheet
            /*
             * TODO: move the relevant control styles into their own stylesheet,
             * so we don't need to update all of var-components unnecessarily
             */
            appendStyle('row_components', row_components(this.state))
        }
    }

    render() {
        const props = this.props;
        return (
            <div
                className={`row ${propOr('', 'className', props)}`}
                {...props}
            >
                {props.children}
            </div>
        );
    }
}

Row.propTypes = {
    /**
     * The ID of this component, used to identify Dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string,

    /**
     * The list of components that are children of the Row container.
     */
    children: PropTypes.node,

    /**
     * Overrides the default (inline) styles for the this component.
     */
    style: PropTypes.object,

    /**
     * Optional user-defined CSS class for the Row container.
     */
    className: PropTypes.string,
}

export default Row;
