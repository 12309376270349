import { css, objectToStyles } from '../utils.js';

const themedStyles = css`
    .CalendarDay {
      border-color: ${props => props.theme.border};
      color: ${props => props.theme.text}; }
    .CalendarDay__button {
      border-color: 0; }
      .CalendarDay__button:active {
        outline-color: 0; }
    .CalendarDay--highlighted-calendar {
      background-color: ${props => props.theme.accent};
      color: ${props => props.theme.text}; }
      .CalendarDay--highlighted-calendar:active {
        background-color: ${props => props.theme.background_content}; }
    .CalendarDay--outside {
      border-color: 0; }
      .CalendarDay--outside:active {
        background-color: ${props => props.theme.background_content}; }
    .CalendarDay--hovered {
      background-color: ${props => props.theme.border};
      border-color: ${props => props.theme.border}; }
    .CalendarDay--blocked-minimum-nights {
      color: ${props => props.theme.text};
      background-color: ${props => props.theme.background_content};
      border-color: ${props => props.theme.border}; }
      .CalendarDay--blocked-minimum-nights:active {
        background-color: ${props => props.theme.background_content}; }
    .CalendarDay--selected-span {
      background-color : ${props => props.theme.background_content};
      border-color     : ${props => props.theme.background_content};
      color            : ${props => props.theme.background_content}; }
      .CalendarDay--selected-span.CalendarDay--hovered, .CalendarDay--selected-span:active {
        background-color : ${props => props.theme.background_content};
        border-color     : ${props => props.theme.background_content}; }
      .CalendarDay--selected-span.CalendarDay--last-in-range {
        border-right-color: ${props => props.theme.background_content}; }
    .CalendarDay--hovered-span,
    .CalendarDay--after-hovered-start {
      background-color: ${props => props.theme.background_content};
      border-color: ${props => props.theme.border};
      color: ${props => props.theme.background_content}; }
      .CalendarDay--hovered-span:active,
      .CalendarDay--after-hovered-start:active {
        background-color: ${props => props.theme.border}; }
    .CalendarDay--selected-start,
    .CalendarDay--selected-end,
    .CalendarDay--selected {
      background-color : ${props => props.theme.background_content};
      border-color     : ${props => props.theme.background_content};
      color            : ${props => props.theme.background_content}; }
      .CalendarDay--selected-start:active,
      .CalendarDay--selected-end:active,
      .CalendarDay--selected:active {
        background-color: ${props => props.theme.background_content}; }
    .CalendarDay--blocked-calendar {
      background-color: ${props => props.theme.text};
      color: ${props => props.theme.text}; }
      .CalendarDay--blocked-calendar:active {
        background-color: ${props => props.theme.text}; }
    .CalendarDay--blocked-out-of-range {
      color: ${props => props.theme.text};
      background-color: ${props => props.theme.background_content};
      border-color: ${props => props.theme.border}; }
      .CalendarDay--blocked-out-of-range:active {
        background-color: ${props => props.theme.background_content}; }
    .CalendarMonth__caption {
      color: ${props => props.theme.text}; }
    .CalendarMonthGrid {
      background-color: ${props => props.theme.background_content}; }
    .DayPicker {
      background-color: ${props => props.theme.background_content}; }
    .DayPicker--horizontal {
      background-color: ${props => props.theme.background_content};
      box-shadow: '0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07)' }
    .DayPicker__week-header {
      color: ${props => props.theme.text}; }
      .DayPicker--vertical-scrollable .DayPicker__week-header {
        border-bottom-color: ${props => props.theme.border};
        background-color: ${props => props.theme.background_content}; }
    .DayPickerNavigation__prev--default,
    .DayPickerNavigation__next--default {
      border-color: ${props => props.theme.border};
      background-color: ${props => props.theme.background_content};
      color: ${props => props.theme.text}; }
      .DayPickerNavigation__prev--default:focus, .DayPickerNavigation__prev--default:hover,
      .DayPickerNavigation__next--default:focus,
      .DayPickerNavigation__next--default:hover {
        border-color: ${props => props.theme.border}; }
      .DayPickerNavigation__prev--default:active,
      .DayPickerNavigation__next--default:active {
        background-color: ${props => props.theme.background_content}; }
      .DayPickerNavigation--horizontal .DayPickerNavigation__prev--default svg,
      .DayPickerNavigation--horizontal .DayPickerNavigation__next--default svg {
        fill: ${props => props.theme.text}; }
    .DayPickerNavigation--vertical {
      background-color: ${props => props.theme.background_content};
      box-shadow: '0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07)'; }
      .DayPickerNavigation--vertical .DayPickerNavigation__next--default {
        border-left-color: 0; }
        .DayPickerNavigation--vertical .DayPickerNavigation__prev--default svg,
        .DayPickerNavigation--vertical .DayPickerNavigation__next--default svg {
          fill: ${props => props.theme.border}; }
    .DayPickerKeyboardShortcuts__show,
    .DayPickerKeyboardShortcuts__close {
      border-color: 0; }
    .DayPickerKeyboardShortcuts__show--bottom-right {
      border-top-color: ${props => props.theme.border};
      border-right-color: ${props => props.theme.background_content}; }
      .DayPickerKeyboardShortcuts__show--bottom-right:hover {
        border-right-color: ${props => props.theme.border}; }
    .DayPickerKeyboardShortcuts__show--top-right {
      border-bottom-color: ${props => props.theme.border};
      border-right-color: ${props => props.theme.background_content}; }
      .DayPickerKeyboardShortcuts__show--top-right:hover {
        border-right-color: ${props => props.theme.border}; }
    .DayPickerKeyboardShortcuts__show--top-left {
      border-bottom-color: ${props => props.theme.border};
      border-left-color: ${props => props.theme.background_content}; }
      .DayPickerKeyboardShortcuts__show--top-left:hover {
        border-left-color: ${props => props.theme.border}; }
    .DayPickerKeyboardShortcuts__show_span {
      color: ${props => props.theme.background_content}; }
    .DayPickerKeyboardShortcuts__panel {
      background-color: ${props => props.theme.background_content};
      border-color: ${props => props.theme.border}; }
      .DayPickerKeyboardShortcuts__close svg {
        fill: ${props => props.theme.text}; }
        .DayPickerKeyboardShortcuts__close svg:hover, .DayPickerKeyboardShortcuts__close svg:focus {
          fill: ${props => props.theme.text}; }
    .KeyboardShortcutRow__key {
      background-color: ${props => props.theme.background_content}; }
    .DateInput {
      color: ${props => props.theme.text};
      background-color: ${props => props.theme.background_content};
    }
    .DateInput--with-caret::before,
    .DateInput--with-caret::after {
      border-color: ${props => props.theme.border};
      border-top-color: 0; }
    .DateInput--with-caret::before {
      border-bottom-color: '0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07)'; }
    .DateInput--with-caret::after {
      border-bottom-color: ${props => props.theme.background_content}; }
    .DateInput--disabled {
      background-color: ${props => props.theme.text}; }
    .DateInput__input {
      border-color: 0; }
    .DateInput__display-text--has-input {
      color: ${props => props.theme.border}; }
    .DateInput__display-text--focused {
      background-color: ${props => props.theme.background_content};
      border-color: ${props => props.theme.background_content};
      color: ${props => props.theme.background_content}; }
    .screen-reader-only {
      border-color: 0; }
    .DateRangePicker {
      ${ props => objectToStyles(props.theme.border_style)};
      border-color: ${props => props.theme.border};
    }
    .DateRangePicker__picker {
      background-color: ${props => props.theme.background_content}; }
    .DateRangePicker__picker--portal {
      background-color: rgba(0, 0, 0, 0.3); }
    .DateRangePicker__picker--full-screen-portal {
      background-color: ${props => props.theme.background_content}; }
    .DateRangePicker__close {
      border-color: 0; }
      .DateRangePicker__close svg {
        fill: ${props => props.theme.text}; }
      .DateRangePicker__close:hover, .DateRangePicker__close:focus {
        color: ${props => props.theme.text}; }
    .DateRangePickerInput {
      background-color: ${props => props.theme.background_content};
      border: none;
      }
    .DateRangePickerInput--disabled {
      background-color: ${props => props.theme.text}; }
    .DateRangePickerInput__arrow svg {
      fill: ${props => props.theme.border}; }
    .DateRangePickerInput__clear-dates {
      border-color: 0; }
    .DateRangePickerInput__clear-dates svg {
      fill: ${props => props.theme.text}; }
    .DateRangePickerInput__clear-dates:focus,
    .DateRangePickerInput__clear-dates--hover {
      background-color: ${props => props.theme.border}; }
    .DateRangePickerInput__calendar-icon {
      border-color: 0; }
      .DateRangePickerInput__calendar-icon svg {
        fill: ${props => props.theme.text}; }
    .SingleDatePicker__picker {
      background-color: ${props => props.theme.background_content}; }
    .SingleDatePicker__picker--portal {
      background-color: rgba(0, 0, 0, 0.3) }
    .SingleDatePicker__picker--full-screen-portal {
      background-color: ${props => props.theme.background_content}; }
    .SingleDatePicker__close {
      border-color: 0; }
      .SingleDatePicker__close svg {
        fill: ${props => props.theme.text}; }
      .SingleDatePicker__close:hover, .SingleDatePicker__close:focus {
        color: ${props => props.theme.text}; }
    .SingleDatePickerInput {
      background-color: ${props => props.theme.background_content}; }
      border-color: ${props => props.theme.border}; }
    .SingleDatePickerInput__clear-date {
      border-color: 0; }
    .SingleDatePickerInput__clear-date svg {
      fill: ${props => props.theme.text}; }
    .SingleDatePickerInput__clear-date:focus,
    .SingleDatePickerInput__clear-date--hover {
      background-color: ${props => props.theme.border}; }
    .SingleDatePickerInput__calendar-icon {
      border-color: 0; }
      .SingleDatePickerInput__calendar-icon svg {
        fill: ${props => props.theme.text}; }
    `
export default themedStyles;
