import { css, objectToStyles } from '../utils.js';
import * as R          from 'ramda';


const themedStyles = css`
    .ddk-container.ddk-container .Select-input > input,
    .ddk-container.ddk-container .Select-input > input:not([type]) {
      border-color: none;
      border-width: 0;
      border-style: none;
      border-radius: 0;
      padding: initial;
    }

    .ddk-container.ddk-container .Select-arrow {
        vertical-align: top;
        border-top-color    : ${props => props.theme.text};
        border-left-color   : transparent;
        border-right-color  : transparent;
        border-bottom-color : transparent;
    }

    .ddk-container.ddk-container .is-open > .Select-control .Select-arrow {
        top: initial;
        border-bottom-color: ${props => props.theme.text};
        border-left-color: transparent;
        border-right-color: transparent;
        border-top-color: transparent;
    }

    .ddk-container.ddk-container .Select--multi .Select-value,
    /* TODO - Missing a .ddk-container.ddk-container here? */
    .Select--multi a.Select-value-label {
        color: ${props => props.theme.text};
        user-select: none;
    }

    .ddk-container.ddk-container .Select-control {
        ${props => objectToStyles(props.theme.border_style)}
    }

    .ddk-container.ddk-container .Select.is-disabled > .Select-control {
        border: none;
    }

    .ddk-container.ddk-container .Select-control:hover {
        border-color: ${props => props.theme.border};
        cursor: pointer;
    }

    .ddk-container.ddk-container .Select--multi .Select-value-icon:hover,
    /* TODO - Missing a .ddk-container.ddk-container here? */
    .Select--multi .Select-value-icon:focus {
        font-weight: bold;
    }

    .ddk-container.ddk-container .Select.is-disabled .Select-control {
        cursor: not-allowed;
    }

    .ddk-container.ddk-container .Select-clear-zone {
        display: inline-flex;
        position: absolute;
        width: auto;
        bottom: 0px;
        right: 0px;
        vertical-align: bottom;
        color: ${props => props.theme.text}
    }

    .ddk-container.ddk-container .Select-clear-zone:hover {
        color: ${props => props.theme.accent}
    }

    .ddk-container.ddk-container .Select-clear-zone .Select-clear {
        display: inline-block;
        vertical-align: bottom;
    }

    .ddk-container.ddk-container .Select-arrow-zone {
        display: inline-flex;
        position: absolute;
        width: auto;
        right: 0;
        top: 5px;
        vertical-align: top;
        padding-right: initial;
        color: ${props => props.theme.text}
    }

    .ddk-container.ddk-container .Select-arrow-zone:hover {
        color: ${props => props.theme.accent}
    }
`
export default themedStyles;
