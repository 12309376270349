import { css } from '../utils.js';
import chroma from 'chroma-js';

const themedStyles = css`
    .ddk-container.ddk-container .CalendarDay__default {
        background-color: ${props => props.theme.background_content}
    }

    .ddk-container.ddk-container .DateRangePicker__picker {
        background-color: ${props => props.theme.background_content};
    }

    .ddk-container.ddk-container .DayPickerKeyboardShortcuts_show {
        display: none;
    }

    .ddk-container.ddk-container .CalendarMonth {
        background: ${props => props.theme.background_content};
    }

    .ddk-container.ddk-container .DayPickerNavigation_button__default {
        background-color: ${props => props.theme.background_content};
    }

    .ddk-container.ddk-container .DateInput_fang {
        display: none;
    }

    .DateRangePickerInput_arrow svg {
        fill: ${props => props.theme.border}
    }
`
export default themedStyles;
