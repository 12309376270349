import { css } from '../utils.js';
import tinycolor from 'tinycolor2'

const getContrastingColor = (baseColor, theme) => {
    const { background_content, background_page, text, accent } = theme
    return tinycolor.mostReadable(
        baseColor,
        [background_content, background_page, text, accent],
        /* returns black or white if none of the supplied
           theme colors are readable enough */
        {includeFallbackColors:true}
    ).toHexString();
}

// mutates CSS with no return value
const themedStyles = css`
        body .dash-debug-menu--closed {
            background-color: ${props => getContrastingColor(props.theme.background_page, props.theme)};
        }
        body .dash-debug-menu--opened {
            box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25),
                0px 1px 3px ${props => props.theme.border};
            background-color: ${props => props.theme.background_content};
        }
        body .dash-debug-menu--closed:hover {
            background-color: ${props => tinycolor(getContrastingColor(props.theme.background_page, props.theme)).darken().toString()};
        }
        body .dash-debug-menu__button-label {
            color: {props => props.theme.border};
        }
        body .dash-debug-menu__button {
            background-color: ${props => props.theme.background_content};
            border: 1px solid ${props => props.theme.border};
            color: ${props => props.theme.text};
        }
        body .dash-debug-menu__button--enabled {
            background-color: ${props => props.theme.accent_positive};
            color: ${props => getContrastingColor(props.theme.accent_positive, props.theme)};
        }
        body .dash-debug-menu__button--small {
            background-color: ${props => props.theme.border};
        }
        body .dash-debug-menu__button:hover {
            background-color: ${props => tinycolor(props.theme.background_content).darken().toString()};
        }
        body .dash-debug-menu__button--small:hover {
            background-color: ${props => props.theme.border};
        }
        body .dash-debug-menu__button--enabled:hover {
            background-color: ${props => props.theme.accent_positive};
        }

        body svg.dash-debug-menu__icon.dash-debug-menu__icon--debug path {
            fill: ${props => getContrastingColor(getContrastingColor(props.theme.background_page, props.theme), props.theme)};
        }
`

export default themedStyles;
