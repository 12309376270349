import { css } from '../utils.js';
import chroma from 'chroma-js';
import tinycolor from 'tinycolor2';

const getReadable = (background, text) => {
    return (tinycolor.isReadable(background, text)) ? text :
        tinycolor.mostReadable(
            background,
            [text, tinycolor(text).darken(20), tinycolor(text).lighten(20)],
        ).toHexString();
}

const themedStyles = css`
/* jmblog.github.com/color-themes-for-google-code-highlightjs */

.hljs {
    background-color: ${props => props.theme.background_content};
}

/* comments */
.hljs-comment,
.hljs-quote {
  color: ${props => getReadable(props.theme.background_content, '#898989')};
}

/* vars, tags, selectors... */
.hljs-variable,
.hljs-template-variable,
.hljs-tag,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class,
.hljs-regexp,
.hljs-deletion {
  color: ${props => getReadable(props.theme.background_content, props.theme.accent_negative)};
}

/* numbers, literals, types... */
.hljs-number,
.hljs-built_in,
.hljs-builtin-name,
.hljs-literal,
.hljs-type,
.hljs-params,
.hljs-meta,
.hljs-link {
  color: ${props => getReadable(props.theme.background_content, props.theme.colorway[4])};
}

/* attributes */
.hljs-attribute {
  color: ${props => getReadable(props.theme.background_content, props.theme.colorway[3])};
}

/* strings... */
.hljs-string,
.hljs-symbol,
.hljs-bullet,
.hljs-addition {
  color: ${props => getReadable(props.theme.background_content, props.theme.accent)};
}

.hljs-title,
.hljs-section {
  color: ${props => getReadable(props.theme.background_content, props.theme.colorway[1])};
}

.hljs-keyword,
.hljs-selector-tag {
  color: ${props => getReadable(props.theme.background_content, props.theme.colorway[2])};
}
`
export default themedStyles;
