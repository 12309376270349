import { css } from '../utils.js';
import tinycolor from 'tinycolor2'

const computedBodyBackground = () => {
    let body = document.querySelector('body .ddk-container');
    if (body) {
        let bodyStyles = window.getComputedStyle(body);
        return bodyStyles.getPropertyValue('background-color');
    } else {
        return 'fff'
    }
};

const getContrastingColor = (baseColor, theme) => {
    const { background_content, background_page, text, accent } = theme
    return tinycolor.mostReadable(
        baseColor,
        [background_content, background_page, text, accent],
        /* returns black or white if none of the supplied
           theme colors are readable enough */
        {includeFallbackColors:true}
    ).toHexString();
}

const themedEditButton = css`
    .ddk-container.ddk-container button.edit-theme-button {
        /* get the best contrast with the coputed background */
        color: ${props => getContrastingColor(computedBodyBackground(), props.theme)};
        /* now get the best (most readable) text contrast from the above background */
        background-color: ${props => getContrastingColor(getContrastingColor(computedBodyBackground(), props.theme), props.theme)};
        position: fixed;
        bottom: 10px;
        left: 10px;
        z-index: 1000;
        user-select: none;
        outline-style:none;
        box-shadow:none;
        border-color:transparent;
        border-radius: 4px;
    }

    .ddk-container.ddk-container button.edit-theme-button:hover {
        background-color: inherit;
        color: inherit;
    }
`
export default themedEditButton;
