import { css } from '../utils.js';

const themedStyles = css`
    .VirtualizedSelectFocusedOption {
      cursor: pointer;
    }

    .VirtualizedSelectOption {
        display: block;
        min-width: 0px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .VirtualizedSelectOption::before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }

    .VirtualizedSelectFocusedOption, .VirtualizedSelectSelectedOption {
      color: ${props => props.theme.accent};
      background-color: ${props => props.theme.background_page}
    }

    .ReactVirtualized__Grid.ReactVirtualized__List.VirtualSelectGrid::-webkit-scrollbar {
        width: 0.5em;
    }

    .ReactVirtualized__Grid.ReactVirtualized__List.VirtualSelectGrid::-webkit-scrollbar-thumb {
        background-color: ${props => props.theme.accent};
    }
`
export default themedStyles;
