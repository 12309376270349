/**
 * Logo Component
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {has} from 'ramda';
import dispatchResizeEvent from './../polyfill/dispatchResizeEvent';

/**
 * A component to display logos (images) within `ddk.Header` or `ddk.Sidebar`.
 */
export default class Logo extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { src, alt } = this.props;

        return (
            <div
                className={`layout-logo ${has('className', this.props) ? this.props.className : ''}`}
                style={this.props.style}
            >
                <img onLoad={dispatchResizeEvent} src={src} alt={alt} />
            </div>
        )
    }
}

Logo.propTypes = {
    /**
     * The image URL. This can be local or remote.
     * For local images, place your image in the apps
     * `assets/` folder and pass in a `src` URL like
     * `/assets/logo.png`.
     */
    src: PropTypes.string,

    /**
     * The alt attribute contains a textual description of the image,
     * which isn't mandatory but is incredibly useful for accessibility
     *   - screenreaders read this description out to their users so
     * they know what the image shows, and it is also displayed on the
     * page if the image can't be loaded for some reason.
     */
    alt: PropTypes.string,

    /**
     * Optional style on the div that wraps the underlying logo `img`
     */
    style: PropTypes.object,

    /**
     * className applied to the div that wraps the underlying logo `img`
     */
    className: PropTypes.string,
};
