import { css, objectToStyles } from '../utils.js';
import {omit}          from 'ramda';
import chroma          from 'chroma-js';
import {BORDERS} from '../constants';

const themedStyles = css`
    .ddk-container .row {
        display: flex;
    }
    .ddk-container .row:after {
      content: "";
      display: table;
      clear: both;
    }

    .ddk-container .block {
        display: inline-block;
        flex-direction: column;
        vertical-align: top;
    }

    .ddk-container .card {
        display: inline-flex;
        box-sizing: border-box;
        background-color: ${props => props.theme.card_background_color};
    }
    .ddk-container .card:not(.card--content) {
        box-shadow: ${props => props.theme.card_box_shadow};
        margin: ${props => props.theme.card_margin};
        padding: ${props => props.theme.card_padding};
        /* partial workaround for overlapping borders with 0 margins */
        border-width: ${props => props.theme.card_border.width};
        border-style: ${props => props.theme.card_border.style};
        border-color: ${props => props.theme.card_border.color};
        border-radius: ${props => props.theme.card_border.radius};

        outline-width: ${props => props.theme.card_outline.width};
        outline-style: ${props => props.theme.card_outline.style};
        outline-color: ${props => props.theme.card_outline.color};
    }

    .ddk-container .layout-header {
        background-color: ${props => props.theme.header_background_color};
        box-shadow: ${props => props.theme.header_box_shadow};
        margin: ${props => props.theme.header_margin};
        padding: ${props => props.theme.header_padding};
        /* partial workaround for overlapping borders with 0 margins */
        border-width: ${props => props.theme.header_border.width};
        border-style: ${props => props.theme.header_border.style};
        border-color: ${props => props.theme.header_border.color};
        border-radius: ${props => props.theme.header_border.radius};
    }

    .ddk-container .ddk-page .card {
        background-color: ${props => props.theme.report_background_content};
    }

    .ddk-container .ddk-page .card-header,
    .ddk-container .ddk-page .control-below {
        background-color: ${props => props.theme.report_background_content};
    }

    .ddk-container .card.card--modal {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100;
        margin: 0px;
        z-index: 1005;
        background-color: rgba(0,0,0,0.2);
    }
    .ddk-container
    .card.card--modal
    .card--content {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
    }
    .ddk-container
    .card.card--modal
    .card--content
    .card--content:not(.controls) {
        flex-grow: 1;
        justify-content: center;
    }
    .ddk-container
    .card
    .card--content.controls
    header.card-header, 
    .ddk-container
    .card
    .card--content.controls
    footer.card-footer {
        width: 100%;
    }
    .ddk-container
    .card
    .card--content:not(.controls) {
        margin: 0;
        height: 100%;
    }
    /* allow side-by-side graphs in modal/fullscreen */
    .ddk-container
    .card.card--content {
        display: inline-block;
    }

    .ddk-container
    .control {
        display: flex;
        align-items: center;
        padding: 10px;
    }
    .ddk-container
    .control
    .control--label {
        padding: 2px;
        font-size: 0.9em;
    }
    .ddk-container
    .control
    .control--item {
        padding: 2px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .ddk-container
    .control
    .control--item
    input:not([type="radio"]):not([type="checkbox"]) {
        width: 100%;
    }

    .ddk-container
    .control.label--right {
        flex-direction: row-reverse;
    }
    .ddk-container
    .control.control--vertical .control--label {
        flex: 1;
        padding: 2px 10px;
    }
    .ddk-container
    .control.control--vertical.label--top .control--label {
        flex: 0;
        padding-bottom: 20px;
    }

    .ddk-container
    .control.label--top,
    .ddk-container
    .control.label--bottom {
        flex-direction: column;
    }
    .ddk-container
    .control.label--top.label--bottom,
    .ddk-container
    .control.label--bottom.label--bottom {
        flex-direction: column-reverse;
    }
    .ddk-container
    .control.label--top.label--text--left,
    .ddk-container
    .control.label--bottom.label--text--left {
        align-items: flex-start;
    }
    .ddk-container
    .control.label--top.label--text--center,
    .ddk-container
    .control.label--bottom.label--text--center {
        align-items: center;
    }
    .ddk-container
    .control.label--top.label--text--right,
    .ddk-container
    .control.label--bottom.label--text--right {
        align-items: flex-end;
    }
    .ddk-container
    .control.label--top .control--item,
    .ddk-container
    .control.label--bottom .control--item {
        width: 100%;
    }
    .ddk-container
    .control.label--top .control--item input[type="text"],
    .ddk-container
    .control.label--bottom .control--item input[type="text"] {
        width: 100%;
    }

    .ddk-container
    .controls {
        display: flex;
    }
    .ddk-container
    .controls.horizontal.center {
        justify-content: center;
    }
    .ddk-container
    .controls.horizontal.right {
        justify-content: flex-end;
    }
    .ddk-container
    .controls.vertical.center {
        align-items: center;
    }
    .ddk-container
    .controls.vertical.right {
        align-items: flex-end;
    }
    .ddk-container
    .controls .control--item {
        flex: 1;
    }
    /* IE 10/11 CSS patch (plotly/dash-design-kit#800) */
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
        .ddk-container
        .controls .control--item {
            flex-basis: auto;
        }
    }
    .ddk-container
    .controls .control--item > div:not(.rc-slider) {
        width: 100%;
    }

    .ddk-container
    .controls .DateRangePicker > div {
        display: flex;
        justify-content: center;
    }
    .ddk-container
    .controls .DateRangePicker__picker {
        left: 50% !important;
        transform: translateX(-50%);
    }
    .ddk-container
    .controls label {
        display: inline-flex;
        padding: 5px;
        align-items: center;
    }
    .ddk-container
    .controls.vertical label {
        display: flex;
    }


    /* consistent border for 'below' controls-in-cards */
    .ddk-container .card-header,
    .ddk-container .control-below,
    .ddk-container .card-footer {
        display: flex;
        align-items: center;
        padding: 5px 10px;
    }

    .ddk-container .card-header,
    .ddk-container .control-below {
        margin: ${props => props.theme.card_header_margin};
        padding: ${props => props.theme.card_header_padding};
        background-color: ${props => props.theme.card_header_background_color};
        border-width: ${props => props.theme.card_header_border.width};
        border-style: ${props => props.theme.card_header_border.style};
        border-color: ${props => props.theme.card_header_border.color};
        border-radius: ${props => props.theme.card_header_border.radius};
        box-shadow: ${props => props.theme.card_header_box_shadow};
    }

    .ddk-container
    .card-footer {
        border-top: ${props => props.theme.border} thin solid;
    }

    .ddk-container.ddk-container .control-below .Select-control,
    .ddk-container.ddk-container .card-header_no-title .Select-control,
    .ddk-container.ddk-container .card-footer .Select-control {
        border: none;
    }

    .ddk-container.ddk-container .control-below .DateRangePicker,
    .ddk-container.ddk-container .card-header_no-title .DateRangePicker,
    .ddk-container.ddk-container .card-footer .DateRangePicker {
        border: none;
        padding-bottom: 1px;
    }

    .ddk-container.ddk-container .control-below .SingleDatePicker,
    .ddk-container.ddk-container .card-header_no-title .SingleDatePicker,
    .ddk-container.ddk-container .card-footer .SingleDatePicker {
        border: none;
    }

    .ddk-container.ddk-container .control-below input,
    .ddk-container.ddk-container .card-header_no-title input,
    .ddk-container.ddk-container .card-footer input {
        border: none;
    }

    .ddk-container.ddk-container .card-header .DateRangePicker {
        border: none;
        padding-bottom: 1px;
    }

    .ddk-container.ddk-container .card-footer .DateRangePicker {
        border: none;
        padding-top: 1px;
    }

    .ddk-container
    .layout-controls {
        display: flex;
    }

    .ddk-container
    .layout-controls .control {
        flex: 1;
    }

    @media only screen
        and (min-width: 500px)
        and (max-width: 800px) {
            .ddk-container:not(.ddk-container--print-context)
            .card-header,
            .ddk-container:not(.ddk-container--print-context)
            .card-footer {
                flex-direction: column;
            }
    }

    .ddk-container
    .dot,
    .ddk-container
    .dot:before,
    .ddk-container
    .dot:after {
        background-color: ${props => props.theme.text};
    }

    .ddk-container .hamburger.is-active .hamburger-inner,
    .ddk-container .hamburger.is-active .hamburger-inner::before,
    .ddk-container .hamburger.is-active .hamburger-inner::after,
    .ddk-container .hamburger-inner,
    .ddk-container .hamburger-inner::before,
    .ddk-container .hamburger-inner::after {
        background-color ${props => props.theme.accent};
    }

.ddk-container .caret {
    border: solid ${props => props.theme.text};
    border-width: 0 2px 2px 0;
    margin-top: -2px; /* offset size of border */
    position: relative;
    left: 10px;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform-origin: 50% 75%;
}

    .ddk-container.ddk-container .layout-header a,
    .ddk-container.ddk-container .layout-sidebar a {
      color: ${props => props.theme.text};
    }

    .ddk-container .layout-sidebar .menu-item.highlighted-item {
        box-shadow: inset 5px 0 0 0 ${props => props.theme.accent}
    }

    .ddk-container .layout-sidebar .menu--sub > .menu-item.highlighted-item {
        box-shadow: inset 0 -4px 0 0 ${props => props.theme.accent}
    }

    .ddk-container.ddk-container .layout-sidebar .menu-item:hover > a {
        color: ${props => props.theme.accent};
    }

    .ddk-container .layout-sidebar .menu-item:hover .caret {
        border-color: ${props => props.theme.accent};
    }

    .ddk-container .layout-sidebar .menu--sub #menu {
        background-color: ${props => props.theme.background_content};
    }

    .ddk-container .layout-header .menu-item:hover .caret {
        border-color: ${props => props.theme.accent};
    }

    .ddk-container.ddk-container .layout-header .menu-item:hover > a {
        color: ${props => props.theme.accent};
    }

    .ddk-container .layout-header .menu-item.highlighted-item {
        box-shadow: inset 0 -4px 0 0 ${props => props.theme.accent}
    }

    .ddk-container .layout-header .menu--sub nav#menu li.menu-item.highlighted-item,
    .ddk-container .layout-header nav#menu[data-open="true"][data-collapsed="true"] li.menu-item.highlighted-item {
        box-shadow: inset 5px 0 0 0 ${props => props.theme.accent}
    }

/* 
    .ddk-container .layout-header nav#menu #menu-items-container {
        background-color: ${props => props.theme.background_content};
    }
*/

    .ddk-container .layout-header nav#menu[data-collapsed="true"] ul#menu-closed .menu--sub .menu-item a {
        flex-direction: row-reverse;
        justify-content: flex-end;
    }

    .ddk-container .layout-header nav#menu[data-collapsed="true"] ul#menu-closed .menu--sub .menu-item a .caret {
        margin-top: -6px;
        left: auto;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
    }

    .ddk-container .layout-header .menu--sub nav#menu {
        background-color: ${props => props.theme.background_content}
    }

    .ddk-container .layout-header .menu--sub #menu-items-container,
    .ddk-container .layout-header nav#menu[data-collapsed="true"] #menu-items-container {
        border: 1px solid ${props => props.theme.border}
        border-top: none;
    }

    .ddk-container .layout-header nav#menu[data-collapsed="true"] ul#menu-closed
    .menu--sub:hover nav#menu ul#menu-closed #menu-items-container {
        border-top: 1px solid ${props => props.theme.border};
    }

    @media only screen
        and (min-width: 0px)
        and (max-width: ${props => props.theme.breakpoint_stack_blocks}) {
        .ddk-container:not(.ddk-container--print-context) .block {
            width: 100% !important;
            margin-left: 0px !important;
            margin-right: 0px !important;
            overflow-x: hidden;
        }
        .ddk-container:not(.ddk-container--print-context) .tabs-parent {
            flex-direction: column;
            width: 100%;
        }
       .ddk-container:not(.ddk-container--print-context)  .row {
            flex-direction: column;
        }
        .ddk-container:not(.ddk-container--print-context) .control-in-card_container {
            width: 100%;
        }
    }

    @media only screen and (max-width: 1080px) {
        .ddk-container:not(.ddk-container--print-context) .tab-container {
            flex-direction: column;
        }

        .ddk-container:not(.ddk-container--print-context)  .tab-container .tab {
            width: 100%;
        }
    }

    .ddk-container .sidebar--content {
        width: 100%;
        overflow: hidden;
    }

    .ddk-container .section-title {
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        font-size: 1.1em;
        margin-top: 1.5em;
        margin-bottom: 1.5em;
        letter-spacing: 0.12em;
        position: relative;
        display: inline-block;
        padding-left: 1em;
        padding-right: 1em;
        vertical-align: middle;
    }

    .ddk-container .section-title .section-title--line {
        position: absolute;
        top: 50%;
        display: block;
        width: calc(100% - 2em); /* 2em = padding of parent .section-title x2 */
        height: 1px;
        background-color: ${props => props.theme.border};
    }

    .ddk-container .section-title .section-title--text {
        background-color: ${props => props.theme.background_page};
        position: relative;
        z-index: 2;
        padding-left: 10px;
        padding-right: 10px;
        display: inline;
    }

    .ddk-container .expandToFull svg .cls-1,
    .ddk-container .copyText svg .cls-1 {
        fill: ${props => props.theme.accent};
    }

    .ddk-container .expandToFull svg .cls-2,
    .ddk-container .copyText svg .cls-2,
    .ddk-container .copyText svg .cls-3 {
        fill: ${props => chroma(props.theme.accent).brighten()};
    }

    .ddk-container .expandToFull:hover svg .cls-1,
    .ddk-container .copyText:hover svg .cls-1 {
        fill: ${props => chroma(props.theme.accent).brighten()};
    }

    .ddk-container .expandToFull:hover svg .cls-2,
    .ddk-container .copyText:hover svg .cls-2 {
        fill: ${props => props.theme.accent};
    }

    .ddk-container .copyText.copied:before {
        border-color: ${props => props.theme.border} transparent transparent transparent;
    }

    .ddk-container .copyText.copied:after {
        background: ${props => props.theme.background_content};
        color: ${props => props.theme.text};
        border-color: ${props => props.theme.border};
    }
`
export default themedStyles;
