import { css } from '../utils.js';
import chroma from 'chroma-js';


const themedStyles = css`
	.ddk-container .Select.is-disabled > .Select-control {
      	background-color: ${props => chroma(props.theme.background_page).alpha(0.5).css()};
	}
	.ddk-container .Select-control {
	  background-color : transparent;
	  border-color     : ${props => props.theme.border};
	  color            : ${props => props.theme.text};
	}
	.ddk-container .Select-control:hover {
	  box-shadow: ${props => chroma(props.theme.accent).brighten().css()};
	}
    .ddk-container .Select-control .Select-input:focus {
	  background-color: ${props => props.theme.background_content};
    }
	.ddk-container Select.is-open > .Select-control {
	  background-color: ${props => props.theme.background_content};
	  background: ${props => props.theme.background_content};
	  border-color: ${props => props.theme.border};
	}
    .ddk-container .Select.is-focused > .Select-control {
      background: ${props => props.theme.background_content};
    }
	.ddk-container .Select.is-open > .Select-control .Select-arrow {
	  border-color: transparent transparent ${props => props.theme.text};
	}
	.ddk-container .Select.is-focused:not(.is-open) > .Select-control {
	  border-color: ${props => props.theme.accent};
	  box-shadow: ${props => chroma(props.theme.accent).brighten().css()};
      background: ${props => props.theme.background_content};
	}
	.ddk-container .Select-placeholder,
	.ddk-container .Select--single > .Select-control .Select-value {
	  color: ${props => props.theme.text};
	}
	.ddk-container .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label,
	.ddk-container .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
	  background-color: ${props => props.theme.background_content};
	  color: ${props => props.theme.text};
	}
	.ddk-container .Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:hover,
	.ddk-container .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:hover,
	.ddk-container .Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus,
	.ddk-container .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
	  background: ${props => props.theme.background_content};
	  color: ${props => props.theme.accent};
	}
    .ddk-container .Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus,
    .ddk-container .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
	    background-color: ${props => props.theme.background_content};
    }
	.ddk-container.ddk-container .Select-input.Select-input > input {
	  background-color: transparent;
	  border-color: none;
	}
	.ddk-container .Select-loading {
	  border-color: ${props => props.theme.border};
	  border-right-color: ${props => props.theme.text};
	}
	.ddk-container .Select-clear-zone {
	  color: ${props => props.theme.text};
	}
    .ddk-container .Select-clear-zone:hover {
      color: ${props => props.theme.accent};
    }
	.ddk-container .Select-arrow {
	  border-color: ${props => props.theme.text};
	}
	.ddk-container .is-open .Select-arrow,
	.ddk-container .Select-arrow-zone:hover > .Select-arrow {
	  border-top-color: ${props => props.theme.accent};
	}
	.ddk-container .Select-menu-outer {
	  background-color : ${props => props.theme.background_content};
	  border-color     : ${props => props.theme.border};
	  border-top-color : ${props => props.theme.border};
	  box-shadow       : ${props => chroma(props.theme.accent).brighten().css()};
	}
	.ddk-container .Select-option {
	  background-color: ${props => props.theme.background_content};
	  color: ${props => props.theme.text};
	}
	.ddk-container .Select-option.is-selected {
      background-color: ${props => chroma(props.theme.background_page).alpha(0.5).css()};
	  color: ${props => props.theme.text};
	}
	.ddk-container .Select-option.is-focused {
	  background-color: ${props => chroma(props.theme.background_content).alpha(0.8).css()};
	  color: ${props => props.theme.text};
	}
	.ddk-container .Select-option.is-disabled {
      color: ${props => chroma(props.theme.text).alpha(0.5).css()};
	}
	.ddk-container .Select-noresults {
      color: ${props => props.theme.text};
      background-color: ${props => chroma(props.theme.text).alpha(0.5).css()};
	}
	.ddk-container .Select--multi .Select-value {
	  background-color: ${props => chroma(props.theme.background_content).alpha(0.8).css()};
	  border-color: ${props => props.theme.accent};
	  color: ${props => props.theme.accent};
	}
	.ddk-container .Select--multi a.Select-value-label {
	  color: ${props => props.theme.accent};
	}
	.ddk-container .Select--multi .Select-value-icon {
	  border-right-color: ${props => props.theme.accent};
	}
	.ddk-container .Select--multi .Select-value-icon:hover,
	.ddk-container .Select--multi .Select-value-icon:focus {
	  background-color: ${props => props.theme.background_content};
	  color: ${props => props.theme.accent};
	}
	.ddk-container .Select--multi .Select-value-icon:active {
	  background-color: ${props => props.theme.accent};
	}
    .Select--multi.Select--rtl .Select-value-icon {
      border-left-color: ${props => props.theme.accent};
    }
	.ddk-container .Select--multi.is-disabled .Select-value {
      background-color: ${props => chroma(props.theme.background_content).alpha(0.5).css()};
	  border-color: ${props => props.theme.border};
	  color: ${props => props.theme.text};
	}
	.ddk-container .Select--multi.is-disabled .Select-value-icon {
	  border-right-color: ${props => props.theme.border};
	}
	.ddk-container .Select--multi.is-disabled .Select-value-icon:hover,
	.ddk-container .Select--multi.is-disabled .Select-value-icon:focus,
	.ddk-container .Select--multi.is-disabled .Select-value-icon:active {
      background-color: ${props => chroma(props.theme.background_content).alpha(0.5).css()};
	}
`;
export default themedStyles;
